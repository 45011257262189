import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import SentimentSatisfiedAltOutlinedIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import TvOutlinedIcon from '@mui/icons-material/TvOutlined';
import BackHandOutlinedIcon from '@mui/icons-material/BackHandOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import CallEndOutlinedIcon from '@mui/icons-material/CallEndOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import LaunchIcon from '@mui/icons-material/Launch';
import { Stack } from '@mui/system';
import { IconButton } from '@mui/material';
import { useMeetingCallStore } from 'store/meeting-call';
import { matchPath, useLocation, useNavigate } from 'react-router';
import { useWorkspaceStore } from 'store/workspace';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React from 'react';
import { copyToClipboard } from 'utils/helpers';
import { enqueueSnackbar } from 'notistack';

type IProps = {
    hiddenExpand?: boolean
}

export default function VideoCallControls(props: IProps) {
    const {connection, controls, weiget} = useMeetingCallStore();
    const navigate = useNavigate();
    const {workspace} = useWorkspaceStore();
    const {pathname} = useLocation();


    const isMeetingCallPage = !!matchPath(`/${workspace?.linkWorkspace}/meeting/call/${connection.channelName}`, pathname);

    const linkMeetingCall = `${workspace?.linkWorkspace}/meeting/call/${connection.channelName}?ownUserId=${connection.ownUserId}`;

    const onStopPropagation = (e: React.MouseEvent) => e.stopPropagation();

    return (
        <div onMouseDown={onStopPropagation} onClick={onStopPropagation}>
            <Stack direction={"row"} gap={1}>
                {!props.hiddenExpand && (
                    <IconButton sx={{backgroundColor: "#777", color: "#FFF"}} onMouseDown={() => navigate(`/${linkMeetingCall}`)}>
                        <LaunchIcon />
                    </IconButton>
                )}

                <IconButton sx={{backgroundColor: "#777", color: "#FFF"}} onMouseDown={() => {
                    copyToClipboard(`${window.location.protocol}//${window.location.hostname}/${linkMeetingCall}`);
                    enqueueSnackbar(`Ссылка скопирована`, {variant: "success"})
                }}>
                    <ContentCopyIcon />
                </IconButton>
                
                <IconButton sx={{backgroundColor: controls.isMicrophone ? "#777" : 'rgb(234,67,53)', color: "#FFF"}} onMouseDown={() => controls.setIsMicrophone(!controls.isMicrophone)}>
                    {controls.isMicrophone ? <MicIcon /> : <MicOffIcon />}
                </IconButton>

                <IconButton sx={{backgroundColor: controls.isCamera ? "#777" : 'rgb(234,67,53)', color: "#FFF"}} onMouseDown={() => controls.setIsCamera(!controls.isCamera)}>
                    {controls.isCamera ? <VideocamOutlinedIcon /> : <VideocamOffOutlinedIcon />}
                </IconButton>

                <IconButton sx={{backgroundColor: "#A00", color: "#FFF"}} onMouseDown={() => {
                    controls.onCallEnd();
                    if (isMeetingCallPage) navigate(`/${workspace?.linkWorkspace}/meeting`);
                }}>
                    <CallEndOutlinedIcon />
                </IconButton>
            </Stack>
        </div>
    )
}
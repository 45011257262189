import { Avatar, IconButton, Typography } from "@mui/material";
import { Box, Stack, useTheme } from "@mui/system";
import { IAgoraRTCRemoteUser, IRemoteAudioTrack, LocalUser, RemoteUser, useRemoteUsers } from "agora-rtc-react";
import { useMeetingCallStore } from "store/meeting-call";
import { useUserContactsStore } from "store/user-contacts";


import { useUserProfileStore } from "store/user-profile";
import { useDraggable, useDroppable, DraggableNode } from "@dnd-kit/core";
import {IMicrophoneAudioTrack, ICameraVideoTrack} from "agora-rtc-react"

import React from "react";
import VideoCallControls from "../VideoCallControls/VideoCallControls";

const idVideoWeiget = 'agora-video-weiget'

type IProps = {
    localMicrophoneTrack: IMicrophoneAudioTrack | null;
    localCameraTrack: ICameraVideoTrack | null;
    remoteUsers: IAgoraRTCRemoteUser[];
}

const cameraStyles: React.CSSProperties = {width: 40, height: 40, borderRadius: '50%'};

export default function AgoraVideoCallWeiget(props: IProps) {
    const theme = useTheme();
    const {connection, controls, weiget} = useMeetingCallStore();
    const remoteUsers = props.remoteUsers;
    const {userContacts} = useUserContactsStore();
    const {userProfile} = useUserProfileStore();

    const {setNodeRef: setFirstDroppableRef} = useDroppable({
        id: idVideoWeiget,
    });

    const {attributes, listeners, setNodeRef, transform} = useDraggable({
        id: idVideoWeiget,
    });

    const style = transform ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    } : undefined;

    return (
        // <Box ref={setFirstDroppableRef} position={'fixed'} left={10} right={10} top={10} bottom={10} zIndex={999}>
            <div ref={setNodeRef} style={{...style, zIndex: 999, position: 'absolute', display: 'inline-flex', left: 16, bottom: 16}} {...listeners} {...attributes}>
                <Stack
                    display={"inline-flex"}
                    borderRadius={5} 
                    padding={2}
                    spacing={2}
                    sx={{backgroundColor: theme.palette.common.black}}
                    maxWidth={320}
                    zIndex={999}
                >
                    <Stack direction={"row"} spacing={1}>
                        {controls.isCamera ? (
                            <>
                                <LocalUser cameraOn={controls.isCamera} micOn={controls.isMicrophone} videoTrack={props.localCameraTrack} style={cameraStyles} />
                                {remoteUsers.map((remoteUser, indexUser) => {
                                    const itemUser = userContacts.find((item) => item.id === remoteUser.uid);
                                    return (
                                        <RemoteUser user={remoteUser} style={cameraStyles} />
                                    )
                                })}
                            </>
                        ) : (
                            <>
                                <Avatar 
                                    src={userProfile?.urlAvatar || ''}
                                    alt={userProfile?.nickname || userProfile?.login}
                                />
                                {remoteUsers.map((remoteUser, indexUser) => {
                                    const itemUser = userContacts.find((item) => item.id === remoteUser.uid);
                                    return (
                                        <Avatar
                                            key={indexUser}
                                            src={itemUser?.urlAvatar}
                                            alt={itemUser?.nickname || itemUser?.login}
                                            sx={{bgcolor: itemUser?.bgColorAvatar}}
                                            spellCheck={indexUser === 0}
                                        />
                                    )
                                })}
                            </>
                        )}
                    </Stack>

                    <VideoCallControls />
                </Stack>
            </div>
        // </Box>
    )
}
import { Typography } from "@mui/material";
import styles from "./LoadingApp.module.scss";
import {GridLoader} from "react-spinners"

type IProps = React.PropsWithChildren<{}>

export default function LoadingApp(props: IProps) {
    return (
        <div className={styles.LoadingApp}>
            <GridLoader size={32} color="#AAA" />
        </div>
    )
}
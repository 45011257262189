import WebSocketProvider from 'providers/WebSocketProvider'
import WorkspaceMenu from 'modules/WorkspaceMenu/WorkspaceMenu'
import React from 'react'
import { Outlet, useNavigate } from 'react-router'
import { useAuthStore } from 'store/auth'

import './LayoutMain.scss'
import Requests from './Requests/Requests'
import AgoraProvider from 'providers/AgoraProvider'
import { useUserProfileStore } from 'store/user-profile'
import LoadingApp from './LoadingApp/LoadingApp'
import { useWorkspaceStore } from 'store/workspace'

type IProps = React.PropsWithChildren<{}>

export default function LayoutMain(props: IProps) {
    const navigate = useNavigate();
    const {workspace} = useWorkspaceStore();
    const { userId, token } = useAuthStore()
    const { userProfile, setUserProfile } = useUserProfileStore()
    const [loading, setLoading] = React.useState<boolean>(true)

    React.useEffect(() => {
        if (userProfile) setTimeout(() => setLoading(false), 200)
    }, [userProfile]);

    React.useEffect(() => {
        if (!token) navigate(`/${workspace?.linkWorkspace}/sign-in`);
    }, [token, workspace])

    const socketUrl = process.env.REACT_APP_BACKEND!.replace(/^(http|https)/, 'ws') + `/ws?token=${token}`

    return (
        <WebSocketProvider url={socketUrl} options={{}}>
            <Requests>
                <AgoraProvider>
                    {loading && <LoadingApp />}
                    <div id="layout-main">
                        <div id="layout-workspace">{userProfile && <WorkspaceMenu />}</div>
                        <div id="layout-col-left" />
                        <div id="layout-col-main">
                            <Outlet />
                        </div>
                        <div id="layout-col-right" />
                    </div>
                </AgoraProvider>
            </Requests>
        </WebSocketProvider>
    )
}

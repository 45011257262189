import { Button } from "@mui/material";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody/ModalBody";
import ModalFooter from "components/Modal/ModalFooter/ModalFooter";
import ModalHeader from "components/Modal/ModalHeader/ModalHeader";

type IProps = {
    open: boolean;
    onClose(): void;
}

export default function ModalPriviewJoinConnect(props: IProps) {

    return (
        <Modal open={props.open}>
            <ModalHeader
                title="Присоединиться к звонку?"
             />

            <ModalFooter>
                <Button color="primary" variant="contained" onClick={props.onClose}>Присоединиться</Button>
            </ModalFooter>
        </Modal>
    )
}
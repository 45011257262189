import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

type IGroupInfo = {
    id: number
    workspaceId: number
    title: string
    description: string
    modeConnection: 'free' | 'invite'
    ownerUserId: number
    bgColorAvatar: string
    urlAvatar: string | null
    timestamp: string | null
}

type IStore = {
    groupInfo: IGroupInfo | null
    setGroupInfo(workspaceGroupInfo: IStore['groupInfo']): void
}

export const useGroupInfoStore = create<IStore>()((set, get, store) => ({
    groupInfo: null,
    setGroupInfo(groupInfo) {
        set({ groupInfo })
    }
}))

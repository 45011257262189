import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';

import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import SentimentSatisfiedAltOutlinedIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import TvOutlinedIcon from '@mui/icons-material/TvOutlined';
import BackHandOutlinedIcon from '@mui/icons-material/BackHandOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import CallEndOutlinedIcon from '@mui/icons-material/CallEndOutlined';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import CloseIcon from '@mui/icons-material/Close';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import { Box, Container, Stack } from "@mui/system";
import { Avatar, AvatarGroup, Button, Grid, IconButton, Paper, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useMeetingCallStore } from "store/meeting-call";
import React from "react";
import { useRemoteUsers, useCurrentUID, IMicrophoneAudioTrack, ICameraVideoTrack, IRemoteAudioTrack, IAgoraRTCRemoteUser, useRemoteAudioTracks } from "agora-rtc-react";
import { useUserContactsStore } from "store/user-contacts";
import VideoCallControls from "../VideoCallControls/VideoCallControls";
import { useUserProfileStore } from "store/user-profile";
import ItemRemoteUser from "./AgoraRemoteUser/AgoraRemoteUser";
import ItemLocalUser from "./AgoraLocalUser/AgoraLocalUser";
import { useWorkspaceStore } from "store/workspace";
import { useAuthStore } from 'store/auth';

type IProps = {
    localMicrophoneTrack: IMicrophoneAudioTrack | null;
    localCameraTrack: ICameraVideoTrack | null;
    remoteUsers: IAgoraRTCRemoteUser[];
}

const sxAspectRation: React.CSSProperties = {aspectRatio: '16/9'}; // соотношение сторон у квадрата

const usersNotAccepted = [
    {id: 1, nickname: 'Петров В.Г', urlAvatar: ''},
    {id: 2, nickname: 'Абрамович Т.Т', urlAvatar: ''}
];

export default function AgoraVideoCallDesktop(props: IProps) {
    const {userId} = useAuthStore();
    const {connection: {channelName, ownUserId}, controls, grid} = useMeetingCallStore();
    const {openMenuWorkspace, setOpenMenuWorkspace} = useWorkspaceStore();
    const {userProfile} = useUserProfileStore();
    const [openListContatcs, setOpenListContacts] = React.useState(false);
    const [search, setSearch] = React.useState("");

    const [widthItem, setWidthItem] = React.useState<number>();
    const [heightItem, setHeightItem] = React.useState<number>();


    const screenRef = React.useRef<HTMLDivElement>(null);
    const gridContainerRef = React.useRef<HTMLDivElement>(null);
    const theme = useTheme();
    const xlMedia = useMediaQuery(theme.breakpoints.up('xl'));
    const lgMedia = useMediaQuery(theme.breakpoints.up('lg'));
    const mdMedia = useMediaQuery(theme.breakpoints.up('md'));
    const smMedia = useMediaQuery(theme.breakpoints.up('sm'));
    const xsMedia = useMediaQuery(theme.breakpoints.up('xs'));

    const {userContacts} = useUserContactsStore();
    const remoteUsers = props.remoteUsers;
    const currentUUId = useCurrentUID();

    const minSize = Math.min(Number(screenRef.current?.getBoundingClientRect().width), Number(screenRef.current?.getBoundingClientRect().height));
    const isHorizontalGridContainer = Number(gridContainerRef.current?.getBoundingClientRect().width) > Number(gridContainerRef.current?.getBoundingClientRect().height);

    const userMe = userContacts.find((item) => item.id === Number(currentUUId));    
    
    const [minWidth, maxHeight, countColumn] = React.useMemo<[string, string, number]>(() => {
        switch (remoteUsers.length + 1) {
            case 1: {
                if (xlMedia) return ['100%', '100%', 1];
                if (lgMedia) return ['100%', '100%', 1];
                if (mdMedia) return ['100%', '100%', 1];
                if (smMedia) return ['100%', '100%', 1];
                if (xsMedia) return ['100%', '100%', 1];
                return [`100%`, '100%', 1];
            }
            case 2: {
                if (xlMedia) return ['50%', '100%', 2];
                if (lgMedia) return ['50%', '100%', 2];
                if (mdMedia) return ['50%', '100%', 2];
                if (smMedia) return ['100%', '100%', 1];
                if (xsMedia) return ['100%', '100%', 1];
                return [`100%`, '100%', 1];
            }
            case 3: {
                if (xlMedia) return ['50%', '100%', 2];
                if (lgMedia) return ['50%', '100%', 2];
                if (mdMedia) return ['50%', '100%', 2];
                if (smMedia) return ['100%', '100%', 1];
                if (xsMedia) return ['100%', '100%', 1];
                return [`100%`, '100%', 1];
            }
            case 4: {
                if (xlMedia) return ['50%', '50%', 2];
                if (lgMedia) return ['50%', '50%', 2];
                if (mdMedia) return ['50%', '50%', 2];
                if (smMedia) return ['100%', '100%', 1];
                if (xsMedia) return ['100%', '100%', 1];
                return [`100%`, '100%', 1];
            }
            case 5: {
                if (xlMedia) return ['33%', '50%', 3];
                if (lgMedia) return ['33%', '100%', 3];
                if (mdMedia) return ['50%', '100%', 2];
                if (smMedia) return ['50%', '100%', 2];
                if (xsMedia) return ['100%', '100%', 1];
                return ['100%', '100%', 1]
            }
            case 6: {
                if (xlMedia) return ['33%', '100%', 3];
                if (lgMedia) return ['33%', '100%', 3];
                if (mdMedia) return ['50%', '100%', 2];
                if (smMedia) return ['50%', '100%', 2];
                if (xsMedia) return ['100%', '100%', 1];
                return ['100%', '100%', 1]
            }
            case 7:
            case 8:
            case 9:
            default: {
                if (xlMedia) return ['33%', '100%', 3];
                if (lgMedia) return ['33%', '100%', 3];
                if (mdMedia) return ['50%', '100%', 2];
                if (smMedia) return ['50%', '100%', 2];
                if (xsMedia) return ['100%', '100%', 1];
                return ['100%', '100%', 1];
            }
        }

    }, [xlMedia, lgMedia, mdMedia, smMedia, xsMedia, remoteUsers.length]);

    // console.log('flexbase', minWidth, `minSize=${minSize}`);

    const left = userProfile ? (openMenuWorkspace ? 312 : 80) : 0;

    React.useEffect(() => {
        if (!gridContainerRef || !gridContainerRef.current) return;

        const fitRectIntoContainer = (rectWidth: number, rectHeight: number, containerWidth: number, containerHeight: number) => {
            const widthRatio = containerWidth / rectWidth;
            const heightRatio = containerHeight / rectHeight;
        
            const ratio = Math.min( widthRatio, heightRatio );
        
            return {
                width: rectWidth * ratio,
                height: rectHeight * ratio,
            };
        };

        const onResize = () => {
            const aspectRatio = 16 / 9;
            const widthContainer = Number(gridContainerRef.current?.offsetWidth);
            const heightContainer = Number(gridContainerRef.current?.offsetHeight);

            const countRows = Math.ceil((remoteUsers.length + 1) / countColumn);

            let widthItem = Math.floor(widthContainer / countColumn - ((countColumn) * 8));
            let heightItem = Math.floor(heightContainer / countRows - ((countRows) * 8));

            console.log(
                `item: ${widthItem.toFixed(0)}x${heightItem.toFixed(0)}`, 
                `cointainer: ${widthContainer.toFixed(0)}x${heightContainer.toFixed(0)}`, 
                'rows/col', `${countRows}/${countColumn}`
            );
            console.log(`${widthContainer}/${heightContainer} > ${aspectRatio} = `, widthContainer / heightContainer > aspectRatio)


            if ((widthContainer / heightContainer) > aspectRatio) {
                setWidthItem(heightItem * aspectRatio / countColumn);
                setHeightItem(heightItem / countRows);
            } else {
                setWidthItem(widthItem);
                setHeightItem(widthItem / aspectRatio);
            }
        };

        onResize();

        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }, [countColumn, remoteUsers.length]);
    
    console.log("grid", grid)

    return (
        <Stack 
            justifyContent={"stretch"}
            alignItems={"stretch"}
            sx={{backgroundColor: "#222"}}
            position={"fixed"}
            top={0} 
            left={left}
            right={0} 
            bottom={0}
        >
            <Box ref={screenRef} sx={{width: "100%", backgroundColor: "#222", overflow: 'auto', flex: 1}}>
                
                <Stack height={"100%"} justifyContent={"center"} alignItems={"center"} direction={"row"} spacing={2}>

                    <Stack ref={gridContainerRef} height={"100%"} width={"100%"} justifyContent={"center"} alignItems={"center"} flex={1}>
                        <Grid container justifyContent={"center"} alignItems={'center'} spacing={1} height={"auto"} maxHeight={"100%"}>

                            <Grid item sx={sxAspectRation} maxWidth={"100%"} maxHeight={maxHeight} width={widthItem} height={heightItem} margin={0} padding={0}>
                                <ItemLocalUser
                                    uid={Number(currentUUId)}
                                    hasAudio={controls.isMicrophone}
                                    hasVideo={controls.isCamera}
                                    cameraOn={controls.isCamera}
                                    micOn={controls.isMicrophone}
                                    videoTrack={props.localCameraTrack}
                                    audioTrack={props.localMicrophoneTrack}
                                />
                            </Grid>

                            {remoteUsers.sort((a, b) => Number(Number(grid.orders[b.uid]) - grid.orders[a.uid])).slice(0, 7).map((remoteUser, indexUser) => (
                                <Grid item key={remoteUser.uid} sx={sxAspectRation} maxWidth={"100%"} maxHeight={maxHeight} width={widthItem} height={heightItem} margin={0} padding={0}>
                                    <ItemRemoteUser remoteUser={remoteUser} />
                                </Grid>
                            ))}

                            {/* Больше или равно 9 участников */}
                            {remoteUsers.length >= 8 && (
                                <Grid item sx={sxAspectRation} maxWidth={"100%"} maxHeight={maxHeight} width={widthItem} height={heightItem} margin={0} padding={0}>
                                    <Box width={'100%'} height={'100%'} sx={{background: '#888'}} position={"relative"} display={"flex"} alignItems={"center"} justifyContent={"center"} borderRadius={1}>
                                        <AvatarGroup total={remoteUsers.length - 8} max={5}>
                                            {remoteUsers.slice(8).map((remoteUser) => {
                                                const itemUser = userContacts.find(item => item.id === remoteUser.uid);
                                                const isSelected = remoteUser.hasAudio;
    
                                                return (
                                                    <Avatar 
                                                        key={remoteUser.uid} 
                                                        alt={itemUser?.nickname || itemUser?.login} 
                                                        src={itemUser?.urlAvatar}
                                                        sx={{borderColor: isSelected ? "success" : "inherit"}}
                                                    />
                                                )
                                            })}
                                        </AvatarGroup>
                                    </Box>
                                </Grid>
                            )}
                            {/* Больше или равно 9 участников */}
                        </Grid>
                    </Stack>
                    
                    {openListContatcs && (
                        <Stack component={Paper} overflow={"auto"} spacing={1} width={320} height={'100%'} p={2}>
                            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                <Typography variant="subtitle1">Список участников</Typography>
                                <IconButton onClick={() => setOpenListContacts(false)}>
                                    <CloseIcon />
                                </IconButton>
                            </Stack>

                            {/* <TextField
                                label="Поиск пользователей"
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value)
                                }}
                                fullWidth
                            /> */}

                            {Number(ownUserId) === userId && (
                                <>
                                    <Typography variant="subtitle2" mt={1}>Ожидают разрешения</Typography>

                                    {/* -- Ожидают подключения -- */}
                                    <Stack spacing={1}>
                                        {usersNotAccepted.map((item) => (
                                            <Stack alignItems={"center"} direction={"row"} spacing={1}>
                                                <Avatar 
                                                    key={item?.id} 
                                                    alt={item?.nickname}
                                                    src={item?.urlAvatar || ''}
                                                />
                                                <Typography variant="body2" flex={1}>{item?.nickname}</Typography>
                                                <IconButton color='info' size='small'><PersonAddIcon /></IconButton>
                                                <IconButton color='error' size='small'><PersonOffIcon /></IconButton>
                                            </Stack>
                                        ))}
                                        
                                    </Stack>
                                    {/* -- Ожидают подключения -- */}
                                </>
                            )}
                            

                            <Typography variant="subtitle2" mt={1}>На встрече</Typography>

                            {/* -- Список участников -- */}
                            <Stack spacing={1}>
                                <Stack alignItems={"center"} direction={"row"} spacing={1}>
                                    <Avatar 
                                        key={userMe?.id} 
                                        alt={userMe?.nickname || userMe?.login} 
                                        src={userMe?.urlAvatar}
                                    />
                                    <Stack direction={"column"}>
                                        <Typography variant="body2">{userMe?.nickname || userMe?.login} (Вы)</Typography>
                                        {Number(ownUserId) === userId && <Typography variant="body2" fontSize={"0.75rem"} color={"GrayText"}>Организатор</Typography>}
                                    </Stack>   
                                </Stack>

                                {remoteUsers.filter((remoteUser) => {
                                    const itemUser = userContacts.find(item => item.id === remoteUser.uid);

                                    return search.trim().length === 0 || (itemUser?.nickname + '' + itemUser?.login).match(new RegExp(`${search}`, 'gi'));
                                }).map((remoteUser) => {
                                    const itemUser = userContacts.find(item => item.id === remoteUser.uid);
                                    const isSelected = remoteUser.hasAudio;

                                    return (
                                        <Stack alignItems={"center"} direction={"row"} spacing={1}>
                                            <Avatar 
                                                key={remoteUser.uid} 
                                                alt={(itemUser?.nickname || itemUser?.login) + (Number(ownUserId) === remoteUser.uid ? `(Организатор)` : ``)} 
                                                src={itemUser?.urlAvatar}
                                                sx={{borderColor: isSelected ? "success" : "inherit"}}
                                            />
                                            <Stack direction={"column"}>
                                                <Typography variant="body2">{itemUser?.nickname || itemUser?.login} {remoteUser.uid === userId ? `(Вы)` : ``}</Typography>
                                                {Number(ownUserId) === userId && <Typography variant="body2" fontSize={"0.75rem"} color={"GrayText"}>Организатор</Typography>}
                                            </Stack>  
                                        </Stack>
                                    )
                                })}
                            </Stack>
                            {/* -- Список участников -- */}

                            

                        </Stack>
                    )}
                    
                </Stack>

            </Box>
            
            <Stack direction={"row"} justifyContent={"space-between"} p={1} mt={"auto"} pl={2} pr={2} sx={{background: '#222'}}>
                <Stack direction={"row"} gap={2} alignItems={"center"} width={250}>
                    <Typography variant="subtitle1" color={"white"}>20:54</Typography>
                    <Typography variant="subtitle1" color={"white"}>{channelName}</Typography>
                </Stack>
                <VideoCallControls hiddenExpand />
                <Stack direction={"row"} width={250} justifyContent={"flex-end"}>
                    <IconButton sx={{backgroundColor: "#777", color: "#FFF"}} onClick={() => setOpenListContacts(open => !open)}>
                        <PeopleOutlinedIcon />
                    </IconButton>
                </Stack>
            </Stack>
        </Stack>
    )
}
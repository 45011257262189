import { Grid, IconButton, Stack, Typography } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import React from 'react'

type IProps = React.PropsWithChildren<{
    title?: string
    subTitle?: string
    onClose?(): void
}>

export default React.memo(function ModalHeader(props: IProps) {
    if (props.children) return <>{props.children}</>

    return (
        <Stack spacing={1} mb={3} direction={'row'} alignItems={'center'}>
            <Stack flex={1}>
                <Typography variant="h5" textAlign="start">
                    {props.title}
                </Typography>
                {props.subTitle && (
                    <Typography variant="h6" color="gray" textAlign="start">
                        {props.subTitle}
                    </Typography>
                )}
            </Stack>
            {props.onClose && (
                <IconButton onClick={props.onClose}>
                    <ClearIcon />
                </IconButton>
            )}
        </Stack>
    )
})

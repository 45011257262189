import instance from "../axios";

const BASE_URL = `https://api.netless.link/v5`;

const REQUSTS_URL = {
    GET_AGORA_TOKENS: `/agora/app-token`,
    GET_AGORA_USER_TOKEN: `/agora/user-token`,
}


// --- Получить токен приложения ---
export interface IGetRtcRtmTokenOfVideoCallResponse {
    rtcToken: string;
    rtmToken: string;
}

export const getAgoraRtcRtmTokensOfVideoCallApi = async (channelName: string, userId: number) => {
    const response = await instance.get<IGetRtcRtmTokenOfVideoCallResponse>(REQUSTS_URL.GET_AGORA_TOKENS, {params: {channelName, userId}});
    return response.data;
};
// --- Получить токен приложения ---

// --- Получить токен пользователя ---
export interface IGetRtcRtmTokenOfVideoCallResponse {
    rtcToken: string;
}

export const getAgoraUserTokenOfVideoCallApi = async (userId: number) => {
    const response = await instance.get<IGetRtcRtmTokenOfVideoCallResponse[]>(REQUSTS_URL.GET_AGORA_TOKENS, {});
    return response.data;
};
// --- Получить токен пользователя ---
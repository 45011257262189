import { create } from 'zustand'
import { n } from '@fullcalendar/core/internal-common'
import { IChatType } from 'api/ws/ws'
import { IItemMessageOfTextChat } from './chat-messages'

export enum DOING_OF_TEXT_CHAT {
    WRITE_TEXT_MESSAGE = 'WRITE_TEXT_MESSAGE',
    WRITE_AUDIO_MESSAGE = 'WRITE_AUDIO_MESSAGE'
}

export type IGroupMember = { groupId: number; memberId: number; confirmInvite: number; isUserBanned: number; isUserLeft: number; timestamp: string | null | {} }

export type IItemChatGroupOfListContacts = {
    id: number
    type: IChatType.GROUP
    title: string
    description: string | null
    modeConnection: 'free' | 'invite'
    ownerUserId: number
    // memberIds: number[],
    members: IGroupMember[]
    bgColorAvatar: string | null
    urlAvatar: string | null
    timestamp: string | null
    isOnline: boolean
    isMuted: boolean
    isPined: boolean
    lastMessage: IItemMessageOfTextChat
    lastMessageViewed: boolean
    unreadCount: number
    unreadMessageIds: number[] | null
    timestampEmailSend: Date
}

type IStore = {
    chatGroupsOfListContacts: IItemChatGroupOfListContacts[]
    setChatGroupsOfListContacts(userContacts: IStore['chatGroupsOfListContacts']): void
}

export const useChatGroupsOfListContactsStore = create<IStore>(set => ({
    chatGroupsOfListContacts: [],
    setChatGroupsOfListContacts: data => set(state => ({ chatGroupsOfListContacts: data }))
}))

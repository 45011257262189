import Modal from 'components/Modal/Modal'
import ModalBody from 'components/Modal/ModalBody/ModalBody'
import ModalFooter from 'components/Modal/ModalFooter/ModalFooter'
import ModalHeader from 'components/Modal/ModalHeader/ModalHeader'
import { Button, Grid, IconButton, InputAdornment, TextField } from '@mui/material'
import { Form, Formik, FormikHelpers, FormikProps, useFormik } from 'formik'
import { ReadyState } from 'react-use-websocket'
import { ISocketRequest, SocketAction } from 'api/ws/ws'
import { useWebSocketContext } from 'providers/WebSocketProvider'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import React from 'react'
import { checkPassword } from 'utils/helpers'

type IProps = {
    open: boolean
    onClose(): void
}

type IInitialValues = {
    password: string
    newPassword: string
    confirmPassword: string
}

export default function ModalEditPassword(props: IProps) {
    const { sendJsonMessage, readyState, lastJsonMessage } = useWebSocketContext()
    const [showPassword, setShowPassword] = React.useState(false)
    const [showNewPassword, setShowNewPassword] = React.useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false)

    const handleClose = () => {
        props.onClose()
    }

    const formikRef = React.useRef<FormikProps<IInitialValues>>(null)

    React.useEffect(() => {
        if (readyState !== ReadyState.OPEN) return

        switch (lastJsonMessage?.action) {
            case SocketAction.TOAST:
                if (lastJsonMessage.payload.type === 'SUCCESS') {
                    handleClose()
                    formikRef.current?.resetForm()
                }
        }
    }, [lastJsonMessage, readyState])

    const handleClickShowPassword = () => setShowPassword(show => !show)
    const handleClickShowNewPassword = () => setShowNewPassword(show => !show)
    const handleClickShowConfirmPassword = () => setShowConfirmPassword(show => !show)

    const initialValues: IInitialValues = {
        password: '',
        newPassword: '',
        confirmPassword: ''
    }

    const handleSubmit = (values: IInitialValues, formikHelpers: FormikHelpers<IInitialValues>) => {
        formikHelpers.setSubmitting(false)
        if (readyState !== ReadyState.OPEN) return
        sendJsonMessage<ISocketRequest>({
            action: SocketAction.UPDATE_USER_PROFILE_OF_WORKSPACE,
            payload: {
                ...values
            }
        })
    }

    return (
        <Modal width={454} open={props.open} onClose={handleClose}>
            <Formik
                initialValues={initialValues}
                validate={values => {
                    const errors: Partial<IInitialValues> = {}
                    if (!values.password) {
                        errors.password = 'Обязательное поле'
                    }
                    if (!values.newPassword) {
                        errors.newPassword = 'Обязательное поле'
                    }
                    if (checkPassword(values.newPassword)) errors.newPassword = checkPassword(values.newPassword)

                    if (!values.confirmPassword) {
                        errors.confirmPassword = 'Обязательное поле'
                    } else if (values.newPassword !== values.confirmPassword) {
                        errors.confirmPassword = 'Пароли не совпадают'
                    }
                    return errors
                }}
                onSubmit={handleSubmit}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, handleReset, setFieldValue, isSubmitting, isValid, dirty }) => (
                    <Form onSubmit={handleSubmit}>
                        <ModalHeader title="Изменить пароль" onClose={props.onClose} />
                        <ModalBody>
                            <TextField
                                label="Старый пароль"
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                variant="outlined"
                                value={values.password}
                                error={touched.password && !!errors.password}
                                helperText={touched.password && errors.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                required
                                size="medium"
                            />
                            <TextField
                                label="Новый пароль"
                                type={showNewPassword ? 'text' : 'password'}
                                name={'newPassword'}
                                value={values.newPassword}
                                error={touched.newPassword && !!errors.newPassword}
                                helperText={touched.newPassword && errors.newPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                fullWidth
                                required
                                size="medium"
                                autoComplete="false"
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" onClick={handleClickShowNewPassword} edge="end">
                                                {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />

                            <TextField
                                label="Подтвердите пароль"
                                type={showConfirmPassword ? 'text' : 'password'}
                                name={'confirmPassword'}
                                value={values.confirmPassword}
                                error={touched.confirmPassword && !!errors.confirmPassword}
                                helperText={touched.confirmPassword && errors.confirmPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                fullWidth
                                required
                                size="medium"
                                autoComplete="false"
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" onClick={handleClickShowConfirmPassword} edge="end">
                                                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button type="submit" variant="contained" color="primary" disabled={!(isValid && dirty)}>
                                Сохранить
                            </Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

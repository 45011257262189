import { ISocketRequest, SocketAction } from "api/ws/ws";
import React from "react";
import { createContext, useMemo } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { Options, WebSocketHook } from "react-use-websocket/dist/lib/types";

type IProps = React.PropsWithChildren<{
  url: string;
  options?: Options;
}>

const WebSocketContext = createContext<WebSocketHook<ISocketRequest | null>>({
  sendMessage() {}, 
  sendJsonMessage() {}, 
  getWebSocket() {
    return null
  }, 
  lastJsonMessage: null, 
  lastMessage: null, 
  readyState: ReadyState.UNINSTANTIATED,
});

export const useWebSocketContext = () => React.useContext<WebSocketHook<ISocketRequest | null>>(WebSocketContext);

const WebSocketProvider = ({ url, children, options }: IProps) => {
  const { sendMessage, sendJsonMessage, getWebSocket, lastJsonMessage, lastMessage, readyState } = useWebSocket<ISocketRequest>(url, {
    onOpen: () => console.log("[open] Соединение открыто!"),
    onError: (error: any) =>  console.log(`[error]`, error),
    onClose: (event: any) => event.wasClean ? console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`) : console.log('[close] Connection died'),
    shouldReconnect:() => true,
    // eventSourceOptions: {
    //   withCredentials: true,
    // },
    ...options,
  });

  const value = useMemo(() => ({sendMessage, sendJsonMessage, getWebSocket, lastJsonMessage, lastMessage, readyState}), [sendMessage, sendJsonMessage, getWebSocket, lastJsonMessage, lastMessage, readyState, url]);

  return (
    <WebSocketContext.Provider
      value={value}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketProvider;
import { Container, Paper, Stack } from '@mui/material'
import React from 'react'
import Profile from 'components/Profile/Profile'
import { useUserProfileStore } from 'store/user-profile'

export default React.memo(function ProfileSettingsPage() {
    const { userProfile } = useUserProfileStore()
    return (
        <Stack justifyContent={'center'} alignItems="center" height={'100%'}>
            <Container component={Paper} maxWidth="md" sx={{ height: 'auto', width: 840, backgroundColor: 'rgba(255, 248, 249, 1)' }}>
                <Profile editable={true} title="Основные данные" subtitle="Ваш профиль" userProfile={userProfile} />
            </Container>
        </Stack>
    )
})

import { Outlet } from "react-router"
import styles from "./LayoutAuth.module.scss"

type IProps = React.PropsWithChildren<{}>

export default function LayoutAuth(props: IProps) {
    return (
        <div className={styles.LayoutAuth}>
            <Outlet />
        </div>
    )
}
import { ListItemButton } from '@mui/material'
import React from 'react'
import { matchPath, NavLink, useLocation, useMatch } from 'react-router-dom'

type IProps = React.PropsWithChildren<{
    children: React.ReactNode
    to: string
    styles?: object
}>

export default function $ListItemButtonLink(props: IProps) {
    const { pathname } = useLocation()
    // const selected = !!useMatch(props.to); // при чётком совпадении пути

    const selected = !!matchPath(`${props.to}/*`, pathname) // при совпадении, если путь чать имени родителя

    console.log("selected", selected, "props.to", props.to, "pathname", pathname)

    return (
        <ListItemButton
            component={NavLink}
            to={props.to}
            selected={selected}
            style={props.styles}
            end
        >
            {props.children}
        </ListItemButton>
    )
}

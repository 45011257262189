import React from 'react'
import Modal from 'components/Modal/Modal'
import ModalBody from 'components/Modal/ModalBody/ModalBody'
import ModalFooter from 'components/Modal/ModalFooter/ModalFooter'
import ModalHeader from 'components/Modal/ModalHeader/ModalHeader'
import { Button, Grid, IconButton, InputAdornment, Link, Stack, TextField } from '@mui/material'
import { Form, Formik, FormikHelpers } from 'formik'
import { ISocketRequest, SocketAction } from 'api/ws/ws'
import { IUserProfile, useUserProfileStore } from 'store/user-profile'
import { ReadyState } from 'react-use-websocket'
import { useWebSocketContext } from 'providers/WebSocketProvider'
import { verifyEmailUserApi } from 'api/auth/auth'
import { enqueueSnackbar } from 'notistack'
import isEmail from 'validator/lib/isEmail'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { checkPassword } from '../../../utils/helpers'
import { b, n } from '@fullcalendar/core/internal-common'

type IProps = {
    open: boolean
    onClose(): void
}

type IInitialValues = Pick<IUserProfile, 'newEmail' | 'verifyEmailCode' | 'password'>

export default function ModalEditEmail(props: IProps) {
    const { userProfile } = useUserProfileStore()
    const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocketContext()
    const [isCodeSent, setIsCodeSent] = React.useState<boolean>(false)
    const [showPassword, setShowPassword] = React.useState<boolean>(false)
    const [isTimerActive, setIsTimerActive] = React.useState<boolean>(false)
    const [timeRemaining, setTimeRemaining] = React.useState<number>(0)

    const handleClickShowPassword = () => setShowPassword(show => !show)

    const handleClose = () => {
        props.onClose()
    }

    const initialValues: IInitialValues = {
        newEmail: '',
        verifyEmailCode: '',
        password: ''
    }

    React.useEffect(() => {
        if (readyState !== ReadyState.OPEN) return

        switch (lastJsonMessage?.action) {
            case SocketAction.TOAST:
                if (lastJsonMessage.payload.type === 'SUCCESS') {
                    setIsCodeSent(true)
                    startTimer()
                }
        }
    }, [lastJsonMessage, readyState])

    React.useEffect(() => {
        let timer: string | number | NodeJS.Timeout | undefined
        if (isTimerActive && timeRemaining > 0) {
            timer = setInterval(() => {
                setTimeRemaining(prev => {
                    if (prev <= 1) {
                        clearInterval(timer)
                        setIsTimerActive(false)
                        return 0
                    }
                    return prev - 1
                })
            }, 1000)
        }

        return () => clearInterval(timer)
    }, [isTimerActive, timeRemaining])

    const startTimer = () => {
        setIsTimerActive(true)
        setTimeRemaining(90) // 90 seconds
    }

    const handleSubmit = async (values: IInitialValues, formikHelpers: FormikHelpers<IInitialValues>) => {
        if (readyState !== ReadyState.OPEN) return
        formikHelpers.setSubmitting(false)
        await verifyEmailUserApi(userProfile?.email!, String(values.verifyEmailCode))
            .then(res => {
                if (!res.error) {
                    sendJsonMessage<ISocketRequest>({
                        action: SocketAction.UPDATE_USER_PROFILE_OF_WORKSPACE,
                        payload: {
                            ...values
                        }
                    })
                }
                formikHelpers.resetForm()
                setIsCodeSent(false)
                handleClose()
            })
            .catch(data => {
                enqueueSnackbar(data.response.data.message, {
                    variant: data.response.data.type === 'ERROR' ? 'error' : 'info'
                })
            })
    }

    return (
        <Modal width={585} open={props.open} onClose={props.onClose}>
            <Formik
                initialValues={initialValues}
                validate={values => {
                    const errors: Partial<IInitialValues> = {}
                    if (!values.password) {
                        errors.password = 'Обязательное поле'
                    }
                    if (!values.newEmail) {
                        errors.newEmail = 'Обязательное поле'
                    } else if (!isEmail(values.newEmail)) {
                        errors.newEmail = 'Введите корректный email'
                    }

                    if (!values.verifyEmailCode) errors.verifyEmailCode = 'Обязательное поле'
                    return errors
                }}
                onSubmit={handleSubmit}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, handleReset, setFieldValue, isSubmitting, isValid, dirty }) => (
                    <Form onSubmit={handleSubmit} autoComplete="note">
                        <ModalHeader title="Изменить Email" onClose={props.onClose} />
                        <ModalBody>
                            <TextField
                                label="Введите пароль"
                                name="password"
                                variant="outlined"
                                value={values.password}
                                error={touched.password && !!errors.password}
                                helperText={touched.password && errors.password}
                                onBlur={handleBlur}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                inputProps={{
                                    type: showPassword ? 'text' : 'password',
                                    autoComplete: 'new-password'
                                }}
                                onChange={handleChange}
                                size="medium"
                            />
                            <Grid container display="flex" justifyContent="space-between" alignItems="center">
                                <Grid item width="65%">
                                    <TextField
                                        label="Новая почта"
                                        name="newEmail"
                                        value={values.newEmail}
                                        error={touched.newEmail && !!errors.newEmail}
                                        helperText={touched.newEmail && errors.newEmail}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        variant="outlined"
                                        size="medium"
                                        inputProps={{
                                            type: 'email',
                                            autoComplete: 'new-email'
                                        }}
                                    />
                                </Grid>
                                <Grid item width="25%">
                                    <Button
                                        variant="text"
                                        type="submit"
                                        color="primary"
                                        disabled={isTimerActive || (!isCodeSent && (!values.password || !values.newEmail))}
                                        onClick={() => {
                                            if (readyState !== ReadyState.OPEN) return
                                            if (!values.newEmail) return
                                            sendJsonMessage<ISocketRequest>({
                                                action: SocketAction.UPDATE_USER_PROFILE_OF_WORKSPACE,
                                                payload: {
                                                    password: values.password,
                                                    newEmail: values.newEmail,
                                                    email: userProfile?.email
                                                }
                                            })
                                        }}
                                    >
                                        Отправить код{' '}
                                        {timeRemaining > 0 && (
                                            <span>
                                                {Math.floor(timeRemaining / 60)}:{timeRemaining % 60 < 10 ? `0${timeRemaining % 60}` : timeRemaining % 60}
                                            </span>
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>
                            <TextField
                                label="Верификационный код"
                                name="verifyEmailCode"
                                type="text"
                                value={values.verifyEmailCode}
                                error={touched.verifyEmailCode && !!errors.verifyEmailCode}
                                onChange={handleChange}
                                helperText="На введённую почту придёт код"
                                variant="outlined"
                                size="medium"
                                disabled={!isCodeSent}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="contained" color="primary" disabled={!values.verifyEmailCode || !isCodeSent} type="submit">
                                Сохранить
                            </Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

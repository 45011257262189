import { ROLES, useUserProfileStore } from "store/user-profile";

type IProps = React.PropsWithChildren<{
    roles: ROLES[]
}>;

export default function $ProtectedComponent(props: IProps) {
    const {userProfile} = useUserProfileStore();
    if (userProfile && props.roles.includes(userProfile.roleId)) return (
        <>{props.children}</>
    );
    return null;
}
import { useSnackbar } from 'notistack'
import { IChatType, ISocketRequest, SocketAction } from 'api/ws/ws'
import { ReadyState } from 'react-use-websocket'
import { useAuthStore } from 'store/auth'
import { useUserContactsStore } from 'store/user-contacts'
import { useUserProfileStore } from 'store/user-profile'

import { useWebSocketContext } from 'providers/WebSocketProvider'
import React from 'react'
import { useWorkspaceStore } from 'store/workspace'
import { useChatGroupsOfListContactsStore } from 'store/group-contacts'
import { useUserProfile } from 'hooks/useUserProfileHook'
import { useGroupInfoStore } from 'store/group-info'
import { useNavigate, useParams } from 'react-router'
import { useChatChannelsOfListContactsStore } from 'store/channel-contacts'
import { getHelperStateTypingTextOfTextChat, useChatMessagesStore } from 'store/chat-messages'
import { useShopExtensionsStore } from 'store/shop-extensions'

type IProps = React.PropsWithChildren<{}>

let idTimeoutTypingText: NodeJS.Timeout

export default function Requests(props: IProps) {
    const { userId, token } = useAuthStore()
    const { chatId, chatType } = useParams<{ chatId: string; chatType: string }>()
    const { userProfile } = useUserProfileStore()
    const { workspace } = useWorkspaceStore()
    const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocketContext()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const navigate = useNavigate()
    // --
    const { userContacts, setUserContacts } = useUserContactsStore()
    const { typingTextOfTextChat, typingAudioOfTextChat, setTypingTextOfTextChat, setTypingAudioOfTextChat } = useChatMessagesStore()

    const { setUserProfile, setLoadingUserProfile } = useUserProfileStore()
    const { chatGroupsOfListContacts, setChatGroupsOfListContacts } = useChatGroupsOfListContactsStore()
    const { setGroupInfo } = useGroupInfoStore()
    const { chatChannelsOfListContacts, setChatChannelsOfListContacts } = useChatChannelsOfListContactsStore()
    const { shopExtensions, setShopExtension } = useShopExtensionsStore()
    const { messages, setMessages, page, setPage } = useChatMessagesStore()

    React.useEffect(() => {
        if (readyState !== ReadyState.OPEN) return;

        setLoadingUserProfile(true);
        sendJsonMessage<ISocketRequest>({
            // запросим текущий профиль
            action: SocketAction.GET_USER_PROFILE_OF_WORKSPACE,
            payload: {
                // workspaceId: workspace.id,
                // userId: userId
            }
        }, true);

        sendJsonMessage<ISocketRequest>({
            // запросим спиcок пользователей в пространстве
            action: SocketAction.GET_ALL_USERS_OF_WORKSPACE,
            payload: {
                // workspaceId: workspace.id,
                // userId: userId
            }
        }, true)

        sendJsonMessage<ISocketRequest>({
            // запросим список групп в пространстве
            action: SocketAction.GET_ALL_GROUPS_OF_CHAT_TEXT,
            payload: {}
        }, true)

        sendJsonMessage<ISocketRequest>({
            // запросим список каналов в пространстве
            action: SocketAction.GET_ALL_CHANNELS_OF_CHAT_TEXT,
            payload: {}
        }, true)

        sendJsonMessage<ISocketRequest>({
            // запросим список расширений в пространстве
            action: SocketAction.GET_SHOP_EXTENSIONS_OF_WORKSPACE,
            payload: {}
        }, true)
    }, [readyState, workspace?.id])

    // --
    React.useEffect(() => {
        // получение ответов
        if (!lastJsonMessage || !workspace) return

        switch (lastJsonMessage.action) {
            case SocketAction.GET_USER_PROFILE_OF_WORKSPACE: {
                // сохранение текущего профиля
                setUserProfile(lastJsonMessage.payload)
                setLoadingUserProfile(false);
                break
            }
            case SocketAction.GET_ALL_USERS_OF_WORKSPACE: {
                // сохранение списка контактов
                setUserContacts(lastJsonMessage.payload)
                break
            }

            // ---
            case SocketAction.UPDATE_USER_PROFILE_OF_WORKSPACE: {
                sendJsonMessage<ISocketRequest>({
                    // запросим текущий профиль
                    action: SocketAction.GET_USER_PROFILE_OF_WORKSPACE,
                    payload: {
                        workspaceId: workspace.id,
                        userId: userId,
                        roleId: Number(userProfile?.roleId)
                    }
                }, true)
                setLoadingUserProfile(false);

                sendJsonMessage<ISocketRequest>({
                    // запросим спиcок пользователей в пространстве
                    action: SocketAction.GET_ALL_USERS_OF_WORKSPACE,
                    payload: {
                        workspaceId: workspace.id,
                        userId: userId
                    }
                }, true)
                break
            }

            case SocketAction.CREATE_SERVICE_ACCOUNT:
            case SocketAction.UPDATE_SERVICE_ACCOUNT:
            case SocketAction.SIGN_IN:
            case SocketAction.SIGN_OUT:
            case SocketAction.CREATE_INVITE_USER_BY_EMAIL_OF_WORKSPACE:
            case SocketAction.REMOVE_INVITE_USER_BY_EMAIL_OF_WORKSPACE:
            case SocketAction.CHANGE_INVITE_USER_BY_EMAIL_OF_WORKSPACE: {
                // после успешного приглашения
                console.log('Запросим заново всех пользователей')

                sendJsonMessage<ISocketRequest>({
                    // запросим спиcок пользователей в пространстве
                    action: SocketAction.GET_ALL_USERS_OF_WORKSPACE,
                    payload: {
                        workspaceId: workspace.id,
                        userId: userId
                    }
                }, true)

                sendJsonMessage<ISocketRequest>({
                    // запросим спиcок каналов в пространстве
                    action: SocketAction.GET_ALL_GROUPS_OF_CHAT_TEXT,
                    payload: {}
                }, true)

                sendJsonMessage<ISocketRequest>({
                    // запросим спиcок каналов в пространстве
                    action: SocketAction.GET_ALL_CHANNELS_OF_CHAT_TEXT,
                    payload: {
                        workspaceId: workspace.id,
                        userId: userId
                    }
                }, true)
                break
            }

            // --
            case SocketAction.CREATE_CHAT_MESSAGE: {
                if (chatType === lastJsonMessage.payload.chatType && (Number(chatId) === lastJsonMessage.payload.chatId || userId === lastJsonMessage.payload.chatId)) {
                    setMessages([...messages, ...lastJsonMessage.payload.messages])
                    setPage(Math.floor(messages.length / 12) + 1)
                } else {
                    console.log(lastJsonMessage, chatId, chatType, userId)
                }

                switch (lastJsonMessage.payload.chatType) {
                    case IChatType.PERSONAL: {
                        sendJsonMessage<ISocketRequest>({
                            // запросим спиcок пользователей в пространстве
                            action: SocketAction.GET_ALL_USERS_OF_WORKSPACE,
                            payload: {
                                workspaceId: workspace?.id,
                                userId: userId
                            }
                        }, true)
                        break
                    }
                    case IChatType.CHANNEL: {
                        sendJsonMessage<ISocketRequest>({
                            // запросим спиcок каналов в пространстве
                            action: SocketAction.GET_ALL_CHANNELS_OF_CHAT_TEXT,
                            payload: {
                                workspaceId: workspace?.id,
                                userId: userId
                            }
                        }, true)
                        break
                    }
                    case IChatType.GROUP: {
                        sendJsonMessage<ISocketRequest>({
                            // запросим спиcок каналов в пространстве
                            action: SocketAction.GET_ALL_GROUPS_OF_CHAT_TEXT,
                            payload: {
                                workspaceId: workspace?.id,
                                userId: userId
                            }
                        }, true)
                        break
                    }
                }

                break
            }

            // -- ALL CHATS --
            case SocketAction.UPDATE_VIEWED_MESSAGE_OF_CHAT: {
                switch (chatType) {
                    case IChatType.PERSONAL: {
                        sendJsonMessage<ISocketRequest>({
                            // запросим спиcок пользователей в пространстве
                            action: SocketAction.GET_ALL_USERS_OF_WORKSPACE,
                            payload: {
                                workspaceId: workspace.id,
                                userId: userId
                            }
                        }, true)
                        break
                    }
                    case IChatType.CHANNEL: {
                        sendJsonMessage<ISocketRequest>({
                            // запросим спиcок каналов в пространстве
                            action: SocketAction.GET_ALL_CHANNELS_OF_CHAT_TEXT,
                            payload: {
                                workspaceId: workspace.id,
                                userId: userId
                            }
                        }, true)
                        break
                    }
                    case IChatType.GROUP: {
                        sendJsonMessage<ISocketRequest>({
                            // запросим спиcок каналов в пространстве
                            action: SocketAction.GET_ALL_GROUPS_OF_CHAT_TEXT,
                            payload: {}
                        }, true)
                        break
                    }
                }
                break
            }

            //--

            // -- Groups --
            case SocketAction.GET_ALL_GROUPS_OF_CHAT_TEXT: {
                // получить полный список каналов
                setChatGroupsOfListContacts(lastJsonMessage.payload)
                break
            }
            case SocketAction.GET_CHAT_INFO: {
                setGroupInfo(lastJsonMessage.payload)
                break
            }
            case SocketAction.UPDATE_COMFIRM_INVITE_TO_GROUP_OF_CHAT_TEXT: {
                // принятия приглашения в канала в ЛС
                sendJsonMessage<ISocketRequest>({
                    // запросим спиcок каналов в пространстве
                    action: SocketAction.GET_ALL_GROUPS_OF_CHAT_TEXT,
                    payload: {}
                }, true)
                break
            }
            case SocketAction.UPDATE_INFO_OF_CHAT:
            case SocketAction.DELETE_CHAT: {
                sendJsonMessage<ISocketRequest>({
                    action: SocketAction.GET_CHAT_INFO,
                    payload: {
                        chatType: chatType,
                        chatId: Number(chatId)
                    }
                })

                sendJsonMessage<ISocketRequest>({
                    // запросим спиcок каналов в пространстве
                    action: SocketAction.GET_ALL_CHANNELS_OF_CHAT_TEXT,
                    payload: {}
                }, true)

                sendJsonMessage<ISocketRequest>({
                    // запросим спиcок каналов в пространстве
                    action: SocketAction.GET_ALL_GROUPS_OF_CHAT_TEXT,
                    payload: {}
                }, true)

                sendJsonMessage<ISocketRequest>({
                    // запросим спиcок пользователей в пространстве
                    action: SocketAction.GET_ALL_USERS_OF_WORKSPACE,
                    payload: {
                        workspaceId: workspace.id,
                        userId: userId
                    }
                }, true)
                break
            }

            case SocketAction.CREATE_GROUP_OF_CHAT_TEXT:
            case SocketAction.INVITE_USERS_TO_GROUP_OF_CHAT_TEXT:
            case SocketAction.CANCEL_INVITE_TO_GROUP_OF_CHAT_TEXT:
            case SocketAction.BANNED_MEMBER_OF_GROUP:
            case SocketAction.DELETE_MEMBER_FROM_GROUP: {
                // после удаления группы, запросить заново список всех групп
                console.log('запросим спиcок всех каналов')

                sendJsonMessage<ISocketRequest>({
                    // запросим спиcок групп в пространстве
                    action: SocketAction.GET_ALL_GROUPS_OF_CHAT_TEXT,
                    payload: {}
                }, true)

                sendJsonMessage<ISocketRequest>({
                    // запросим спиcок пользователей в пространстве
                    action: SocketAction.GET_ALL_USERS_OF_WORKSPACE,
                    payload: {
                        workspaceId: workspace.id,
                        userId: userId
                    }
                }, true)
                break
            }
            // -- Groups --

            // --   Channels --
            case SocketAction.GET_ALL_CHANNELS_OF_CHAT_TEXT: {
                // получить полный список каналов
                setChatChannelsOfListContacts(lastJsonMessage.payload)
                break
            }

            case SocketAction.DELETE_MEMBER_FROM_CHANNEL:
            case SocketAction.INVITE_USERS_OF_CHANNELS:
            case SocketAction.CREATE_CHANNEL_OF_CHAT_TEXT: {
                sendJsonMessage<ISocketRequest>({
                    // запросим спиcок каналов в пространстве
                    action: SocketAction.GET_ALL_CHANNELS_OF_CHAT_TEXT,
                    payload: {
                        workspaceId: workspace.id,
                        userId: userId
                    }
                }, true)

                sendJsonMessage<ISocketRequest>({
                    // запросим спиcок пользователей в пространстве
                    action: SocketAction.GET_ALL_USERS_OF_WORKSPACE,
                    payload: {
                        workspaceId: workspace.id,
                        userId: userId
                    }
                }, true)
                break
            }
            // --   Channels --

            case SocketAction.REMOVE_WORKSPACE: {
                // удалить пространтсово
                window.location.reload()
                break
            }
            case SocketAction.TYPING_MESSAGE_OF_CHAT: { // Печатание текста
                const fromUserId = lastJsonMessage.payload.fromUserId
                const chatType = lastJsonMessage.payload.chatType as IChatType
                const chatId = lastJsonMessage.payload.chatId; // chatId = channelId | groupId | userId

                const SetUserIds = new Set<number>([...(typingTextOfTextChat[chatType][chatId] || []), fromUserId])

                // добавляем пользователя, который печатает в данный момент
                const _typingTextOfTextChat = getHelperStateTypingTextOfTextChat(typingTextOfTextChat, chatType, chatId, fromUserId);
                setTypingTextOfTextChat(_typingTextOfTextChat);

                // сбрасываем счётчик "очистки пользователя" если пришло если пользователь продолжает печатать текст
                if (idTimeoutTypingText) clearTimeout(idTimeoutTypingText);

                // очищаем пользователя, который печатал текст, после того как он перечтал печатать
                idTimeoutTypingText = setTimeout(() => {
                    SetUserIds.delete(fromUserId); // удаляем пользователя

                    setTypingTextOfTextChat({
                        ...typingTextOfTextChat,
                        [chatType]: {
                            ...typingTextOfTextChat[chatType],
                            [chatId]: Array.from(SetUserIds || [])
                        }
                    });
                }, 1200);
                break;
            }

            // -- SHOP EXTENSIONS --
            case SocketAction.GET_SHOP_EXTENSIONS_OF_WORKSPACE: {
                
                setShopExtension(lastJsonMessage.payload);
                break
            }
            case SocketAction.CHANGE_DATA_SHOP_EXTENSION_OF_WORKSPACE:
            case SocketAction.CHANGE_INSTALL_SHOP_EXTENSION_OF_WORKSPACE:
            case SocketAction.DELETE_SHOP_EXTENSION_OF_WORKSPACE: {
                sendJsonMessage<ISocketRequest>({
                    // запросим спиcок расширений в пространстве
                    action: SocketAction.GET_SHOP_EXTENSIONS_OF_WORKSPACE,
                    payload: {}
                }, true)
                break
            }
            // -- SHOP EXTENSIONS --

            case SocketAction.TOAST: {
                // переход на новую группу/канал после создания их
                if (lastJsonMessage.payload.chatId && lastJsonMessage.payload.chatType)
                    navigate(`/${workspace.linkWorkspace}/chat-text/${lastJsonMessage.payload.chatId}/${lastJsonMessage.payload.chatType}`)
                // вывод уведомлений
                enqueueSnackbar(lastJsonMessage.payload.message, { variant: lastJsonMessage.payload.type === 'ERROR' ? 'error' : 'info' })
                break
            }
        }

        return () => {

        }
    }, [lastJsonMessage, workspace?.id])
    // --

    return <>{props.children}</>
}

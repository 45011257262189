import { Grid, Stack } from '@mui/material'
import styles from './ModalFooter.module.scss'

type IProps = React.PropsWithChildren<{}>

export default function ModalFooter(props: IProps) {
    return (
        <Stack spacing={3} direction="row" justifyContent="center" className={styles.ModalFooter}>
            {props.children}
        </Stack>
    )
}

import { create } from 'zustand'
import { IUserProfile } from './user-profile'
import { VIEW_CODES } from 'utils/constants'
import { IChatType } from 'api/ws/ws'
import { stepClasses } from '@mui/material'

export type IItemMessageOfTextChat = {
    id: number
    chatId: number
    userId: number
    viewId: number
    viewData: string
    textMessage: string
    audioMessage: string
    videoMessage: string
    imageMessage: string
    viewed: boolean
    timestamp: string | null
    lastMessage: string | null
    lastTimeMessage: string | null
    lastMessageAuthorId: number | null
    countNewMessages: number
    viewCount: number | null
} & Pick<IUserProfile, 'nickname' | 'login' | 'urlAvatar' | 'bgColorAvatar'>

export type ITypingMessage = { [key in IChatType]: { [key: number]: number[] } };

type IStore = {
    page: number
    messages: IItemMessageOfTextChat[]
    typingTextOfTextChat: ITypingMessage // список индификаторов из списка контактов, набирающий текст в данный момент "Пишет..."
    typingAudioOfTextChat: ITypingMessage // список индификаторов из списка контактов, набирающий текст в данный момент "Записывает..."
    typingVideoOfTextChat: ITypingMessage // список индификаторов из списка контактов, набирающий текст в данный момент "Записывает..."

    setPage(page: number): void
    setMessages(messages: IStore['messages']): void
    setTypingTextOfTextChat(typingTextOfTextChat: IStore['typingTextOfTextChat']): void
    setTypingAudioOfTextChat(typingAudioOfTextChat: IStore['typingAudioOfTextChat']): void
    setTypingVideoOfTextChat(typingAudioOfTextChat: IStore['typingVideoOfTextChat']): void
}

export const useChatMessagesStore = create<IStore>(set => ({
    page: 1,
    messages: [],
    typingTextOfTextChat: { group: {}, user: {}, channel: {} }, // {group: {[chatId]: [user1, user2, user3]}}
    typingAudioOfTextChat: { group: {}, user: {}, channel: {} },
    typingVideoOfTextChat: { group: {}, user: {}, channel: {} },

    setPage: page => set(state => ({ page })),
    setMessages: messages => set(state => ({ messages })),
    setTypingTextOfTextChat: typingTextOfTextChat => set(state => ({ typingTextOfTextChat })),
    setTypingAudioOfTextChat: typingAudioOfTextChat => set(state => ({ typingAudioOfTextChat })),
    setTypingVideoOfTextChat: typingVideoOfTextChat => set(state => ({ typingVideoOfTextChat }))
}))

export const getHelperStateTypingTextOfTextChat = (typingTextOfTextChat: IStore["typingTextOfTextChat"], chatType: IChatType, chatId: number, typingUserId: number): IStore["typingTextOfTextChat"] => ({
    ...typingTextOfTextChat,
    [chatType]: {
        ...typingTextOfTextChat[chatType],
        [chatId]: Array.from(new Set([...(typingTextOfTextChat[chatType][chatId] || []), typingUserId]))
    }
})
import instance from '../axios'

interface ISignUpRequest {
    workspaceId: number
    linkWorkspace: string
    firstname?: string
    middlename?: string
    lastname?: string
    login: string
    password: string
    email: string
    birthday?: Date
    verifyEmailCode?: number
}

interface ISignInRequest {
    workspaceId: number
    login: string
    password: string
}

interface IRecoveryAccountRequest {
    workspaceId: number
    login?: string
    email?: string
    linkWorkspace: string
}

interface IResetPasswordRequest {
    workspaceId: number
    password: string
    email: string
    linkWorkspace: string
}

interface IDefaultMessageResponse {
    type: string
    error: boolean
    status: string
    message: string
}

interface ISignInResponse {
    status: string
    userId: number
    token: string
}

interface IUserResponse {
    status: string
    data: {
        user: any
    }
}

export const refreshAccessTokenFn = async () => {
    const response = await instance.get<ISignInResponse>('auth/refresh')
    return response.data
}

export const signUpUserApi = async (user: ISignUpRequest) => {
    const response = await instance.post<IDefaultMessageResponse & { emailConfirm: number; email: string }>('/auth/sign-up', user, { withCredentials: false })
    return response.data
}

export const inviteUserByEmailApi = async (email: string) => {
    const response = await instance.post<IDefaultMessageResponse>('/auth/invite-user-by-email', { email }, { withCredentials: false })
    return response.data
}

export const signInUserApi = async (user: ISignInRequest) => {
    const response = await instance.post<ISignInResponse>('/auth/sign-in', user, { withCredentials: false })
    return response.data
}

export const recoveryAccountUserApi = async (user: IRecoveryAccountRequest) => {
    const response = await instance.post<IDefaultMessageResponse & { login: string }>('/auth/recovery-account', user, { withCredentials: false })
    return response.data
}

export const resetPasswordUserApi = async (user: IResetPasswordRequest) => {
    const response = await instance.post<IDefaultMessageResponse>('/auth/reset-password', user, { withCredentials: false })
    return response.data
}

export const verifyEmailUserApi = async (email: string, verifyCode: string) => {
    const response = await instance.get<IDefaultMessageResponse>(`/auth/verify-email/${email}/${verifyCode}`)
    return response.data
}

export const signOutUserApi = async () => {
    const response = await instance.get<IDefaultMessageResponse>('/auth/sign-out')
    return response.data
}

export const getMeApi = async () => {
    const response = await instance.get<IUserResponse>('/users/me')
    return response.data
}

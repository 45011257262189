import { Container, Grid, Stack, Typography } from "@mui/material";
import urlStopImage from "./stop.svg";
import { ROLES, useUserProfileStore } from "store/user-profile";
import React from "react";

type IProps = React.PropsWithChildren<{
    roles: ROLES[]
}>;

export default function $ProtectedRoute(props: IProps) {
    const displayPath = "/" + window.location.pathname + window.location.search;
    const {userProfile} = useUserProfileStore();

    if (userProfile && props.roles.includes(userProfile.roleId)) return <React.Fragment>{props.children}</React.Fragment>

    return (
        <Stack height={'100%'} justifyItems={"center"} justifyContent={"stretch"}>
            <Container maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs="auto">
                        <img src={urlStopImage} alt="403" width={320} />
                    </Grid>
                    <Grid item>
                        <Typography variant="h1" color="primary">403</Typography>
                        <Typography>Доступ запрещен: {displayPath}</Typography>
                    </Grid>
                </Grid>
            </Container>
        </Stack>
    )
}
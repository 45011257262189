import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

import LocalPoliceIcon from '@mui/icons-material/LocalPolice'
import StarsIcon from '@mui/icons-material/Stars'
import VisibilityIcon from '@mui/icons-material/Visibility'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import SmartToyIcon from '@mui/icons-material/SmartToy'
import { amber, deepOrange, lightBlue, lime, pink, grey } from '@mui/material/colors'
import { Tooltip } from '@mui/material'

export type IUserProfile = {
    id: number
    workspaceId: number
    roleId: number
    login: string
    password: string
    isPasswordCompromised: boolean
    newPassword: string
    nickname: string | null
    firstname: string | null
    middlename: string | null
    lastname: string | null
    birthday: Date | string | null | undefined
    status: string | null
    email: string
    newEmail: string
    emailConfirm: boolean
    verifyEmailCode: number | string
    confirmVerifyEmailCode: boolean
    urlAvatar: string | null
    bgColorAvatar: string
    isOnline: boolean
    timestampEmailSend: string | null
    timestampSignUp: string | null
    timestampSignIn: string | null
    timestampSignOut: string | null
}

export enum ROLES {
    OWNER = 1, // Владелец
    ADMIN = 2, // Администратор
    USER = 3, // Пользователь
    WATCHER = 4, // Наблюдатель
    SERVICE = 5, // Служебная роль
    SYSTEM = 6 // Системная роль
}

export const ROLE_OPTIONS = {
    [ROLES.OWNER]: 'Владелец',
    [ROLES.ADMIN]: 'Администратор',
    [ROLES.USER]: 'Пользователь',
    [ROLES.WATCHER]: 'Наблюдатель',
    [ROLES.SERVICE]: 'Служебная', // не отображать "Служебную" роль
    [ROLES.SYSTEM]: 'Системная' // не отображать "Cлужебную" роль
}

export const ROLE_ICONS = {
    [ROLES.OWNER]: <LocalPoliceIcon sx={{ color: amber[500], maxWidth: '100%', maxHeight: '100%' }} />,
    [ROLES.ADMIN]: <StarsIcon sx={{ color: deepOrange[500], maxWidth: '100%', maxHeight: '100%' }} />,
    [ROLES.USER]: <AccountCircleIcon sx={{ color: lime[500], maxWidth: '100%', maxHeight: '100%' }} />,
    [ROLES.WATCHER]: <VisibilityIcon sx={{ color: lightBlue[500], maxWidth: '100%', maxHeight: '100%' }} />,
    [ROLES.SERVICE]: <SmartToyIcon sx={{ color: grey[500], maxWidth: '100%', maxHeight: '100%' }} />,
    [ROLES.SYSTEM]: <SmartToyIcon sx={{ color: grey[500], maxWidth: '100%', maxHeight: '100%' }} />
}

export function getIconByRole(roleId: ROLES) {
    const item = Object.entries(ROLE_ICONS).find(([id, icon]) => Number(id) === roleId)
    if (!item) return
    const [id, icon] = item
    const label = ROLE_OPTIONS[roleId]
    return <Tooltip title={`Роль: ${label}`}>{icon}</Tooltip>
}

type IStore = {
    loadingUserProfile: boolean
    userProfile: IUserProfile | null
    setLoadingUserProfile(loading: boolean): void
    setUserProfile(workspaceUserProfile: IStore['userProfile']): void
}

export const useUserProfileStore = create<IStore>()(set => ({
    loadingUserProfile: true,
    userProfile: null,
    setLoadingUserProfile(loadingUserProfile) {
        set({ loadingUserProfile })
    },
    setUserProfile(userProfile) {
        set({ userProfile })
    }
}))

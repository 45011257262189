import React from "react";
import ItemWindow from "../components/UserContainer/UserContainer";
import { Avatar, Stack, Typography } from "@mui/material";
import ItemNickname from "../components/Nickname/Nickname";
import { IAgoraRTCRemoteUser, RemoteUser, useClientEvent, useRemoteUsers, useRemoteVideoTracks, useVolumeLevel} from "agora-rtc-react";
import { useUserContactsStore } from "store/user-contacts";
import UserContainer from "../components/UserContainer/UserContainer";
import { useMeetingCallStore } from "store/meeting-call";

type IProps = React.PropsWithChildren<{
    remoteUser: IAgoraRTCRemoteUser;
}>

const sxAvatar: React.CSSProperties = {width: 100, height: 100}; // соотношение сторон у квадарата

export default React.memo(function AgoraRemoteUser(props: IProps) {
    const {userContacts} = useUserContactsStore();
    const {connection, controls, grid} = useMeetingCallStore();
    const itemUser = userContacts.find(item => item.id === props.remoteUser.uid);
    const [isRemoteCamera, setIsRemoteCamera] = React.useState(props.remoteUser.hasVideo);
    const [isRemoteAudio, setIsRemoteAudio] = React.useState(props.remoteUser.hasAudio);

    const volumeLevel = useVolumeLevel();

    useClientEvent(connection.rtcClient, "user-unpublished", (user, mediaType) => {
        if (props.remoteUser.uid === user.uid) {
            if (mediaType === "video") setIsRemoteCamera(false);
            if (mediaType === "audio") setIsRemoteAudio(false);
        }
    });

    useClientEvent(connection.rtcClient, "user-published", (user, mediaType) => {
        if (props.remoteUser.uid === user.uid) {
            if (mediaType === "video") setIsRemoteCamera(true);
            if (mediaType === "audio") setIsRemoteAudio(true);
        }
    });

    const isSpeak = volumeLevel >= 0.5 && isRemoteAudio;

    React.useEffect(() => {
        if (isSpeak) {
            grid.setOrders({...grid.orders, [props.remoteUser.uid]: Date.now()})
        }
    }, [volumeLevel]);
    

    return (
        <UserContainer isSpeak={isSpeak}>
            {!!isRemoteCamera
                ? <RemoteUser user={props.remoteUser} style={{height: '100%', width: '100%'}}></RemoteUser> 
                : <Avatar src={itemUser?.urlAvatar} alt={itemUser?.nickname || itemUser?.login} sx={{bgcolor: itemUser?.bgColorAvatar, ...sxAvatar}} />}
            
            <ItemNickname hasAudio={!!isRemoteAudio}>
                {itemUser?.nickname || itemUser?.login}
            </ItemNickname>
        </UserContainer>
    )
});
export enum IChatType {
    PERSONAL = 'user',
    GROUP = 'group',
    CHANNEL = 'channel'
}

// -- websocket--
export enum SocketAction {
    // -- USER --
    SIGN_IN = 'SIGN_IN',
    SIGN_OUT = 'SIGN_OUT',
    // -- USER --

    // -- WORKSPACE --
    GET_ALL_USERS_OF_WORKSPACE = 'GET_ALL_USERS_OF_WORKSPACE', // Получить полный список пользователей в пространстве
    CHANGE_CONNECTION_MODE_OF_WORKSPACE = 'CHANGE_CONNECTION_MODE_OF_WORKSPACE', // Изменить режим подключения к пространству
    CREATE_INVITE_USER_BY_EMAIL_OF_WORKSPACE = 'CREATE_INVITE_USER_BY_EMAIL_OF_WORKSPACE', // Пригласить пользователя в пространство
    REMOVE_INVITE_USER_BY_EMAIL_OF_WORKSPACE = 'REMOVE_INVITE_USER_BY_EMAIL_OF_WORKSPACE', // Отозвать пользователя из простарнства
    CHANGE_INVITE_USER_BY_EMAIL_OF_WORKSPACE = 'CHANGE_INVITE_USER_BY_EMAIL_OF_WORKSPACE', // Изменить роль пользователя в пространтсве
    UPDATE_WORKSPACE = 'UPDATE_WORKSPACE', // Обновить пространство
    REMOVE_WORKSPACE = 'REMOVE_WORKSPACE', // Удалить пространство
    // -- WORKSPACE --

    // -- USER PROFILE --
    GET_USER_PROFILE_OF_WORKSPACE = 'GET_USER_PROFILE_OF_WORKSPACE', // получить данные текущего профиля
    UPDATE_USER_PROFILE_OF_WORKSPACE = 'UPDATE_USER_PROFILE_OF_WORKSPACE', // обновить данные текущего профиля (аватарку и данные)
    // -- USER PROFILE --

    // -- SERVICE ACCOUNT --
    CREATE_SERVICE_ACCOUNT = 'CREATE_SERVICE_ACCOUNT', // создание служебной учетной записи
    UPDATE_SERVICE_ACCOUNT = 'UPDATE_SERVICE_ACCOUNT', // обновление служебной учетной записи
    // -- SERVICE ACCOUNT --

    // -- CHAT GLOBAL --
    GET_CHAT_INFO = 'GET_CHAT_INFO', // получить информацию о чате (о группе или о канале или профиль пользователя)
    GET_CHAT_MESSAGES = 'GET_CHAT_MESSAGES', // получить историю сообщений
    UPDATE_INFO_OF_CHAT = 'UPDATE_INFO_OF_CHAT', // обновление данных чата (группы, канала)
    CREATE_CHAT_MESSAGE = 'CREATE_CHAT_MESSAGE', // создать текстовое сообщение
    UPDATE_VIEWED_MESSAGE_OF_CHAT = 'UPDATE_VIEWED_MESSAGE_OF_CHAT', // обновить "просмотр" сообщение
    TYPING_MESSAGE_OF_CHAT = 'TYPING_MESSAGE_OF_CHAT', // пользовательское действие ("Печатает сообщение") ("Записывает аудио")
    DELETE_CHAT = 'DELETE_CHAT', // удалить чат (группу, канал)
    // -- CHAT GLOBAL --

    // -- CHAT GROUP --
    GET_ALL_GROUPS_OF_CHAT_TEXT = 'GET_ALL_GROUPS_OF_CHAT_TEXT', // получить полный список групп
    CREATE_GROUP_OF_CHAT_TEXT = 'CREATE_GROUP_OF_CHAT_TEXT', // создать группу
    INVITE_USERS_TO_GROUP_OF_CHAT_TEXT = 'INVITE_USERS_TO_GROUP_OF_CHAT_TEXT', // пригласить новых участников в группу
    UPDATE_COMFIRM_INVITE_TO_GROUP_OF_CHAT_TEXT = 'UPDATE_COMFIRM_INVITE_TO_GROUP_OF_CHAT_TEXT', // принять приглашение в группу
    CANCEL_INVITE_TO_GROUP_OF_CHAT_TEXT = 'CANCEL_INVITE_TO_GROUP_OF_CHAT_TEXT', // отменить приглашение участника в группу
    DELETE_MEMBER_FROM_GROUP = 'DELETE_MEMBER_FROM_GROUP', // удалить участника из группы
    BANNED_MEMBER_OF_GROUP = 'BANNED_MEMBER_OF_GROUP', // блокировка участника группы
    // -- CHAT GROUP --

    // -- CHAT CHANNEL
    GET_ALL_CHANNELS_OF_CHAT_TEXT = 'GET_ALL_CHANNELS_OF_CHAT_TEXT', // получить список каналов
    CREATE_CHANNEL_OF_CHAT_TEXT = 'CREATE_CHANNEL_OF_CHAT_TEXT', // создать канал
    INVITE_USERS_OF_CHANNELS = 'INVITE_USERS_OF_CHANNELS', // пригласить участников канала
    DELETE_MEMBER_FROM_CHANNEL = 'DELETE_MEMBER_FROM_CHANNEL', // удалить участника канала
    // -- CHAT CHANNEL

    // -- SHOP EXTENSIONS --
    GET_SHOP_EXTENSIONS_OF_WORKSPACE = 'GET_SHOP_EXTENSIONS_OF_WORKSPACE',
    CHANGE_INSTALL_SHOP_EXTENSION_OF_WORKSPACE = 'CHANGE_INSTALL_SHOP_EXTENSION_OF_WORKSPACE',
    CHANGE_DATA_SHOP_EXTENSION_OF_WORKSPACE = 'CHANGE_DATA_SHOP_EXTENSION_OF_WORKSPACE',
    DELETE_SHOP_EXTENSION_OF_WORKSPACE = 'DELETE_SHOP_EXTENSION_OF_WORKSPACE',
    // -- SHOP EXTENSIONS --

    // -- TOP DOWN GAME --
    MOVE_CIRCLE_AVATAR = 'MOVE_CIRCLE_AVATAR', // перемещение своего аватара в пространстве
    // -- TOP DOWN GAME --

     // -- CALL MEETINGS --
     CREATE_CALL_MEETING = 'CREATE_CALL_MEETING', // создать звонок и назначить владельца
     UPDATE_CALL_MEETING = 'UPDATE_CALL_MEETING', // обновить звонок
     DELETE_CALL_MEETING = 'DELETE_CALL_MEETING', // удалить звонок 
     ACCEPT_USER_OF_CALL_MEETING = 'ACCEPT_USER_OF_CALL_MEETING', // принять пользователя в звонок
     // -- CALL MEETINGS --
 

    TOAST = 'TOAST',
}

export type ISocketRequest = {
    action: keyof typeof SocketAction
    payload: any
}

// -- websocket --

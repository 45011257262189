import { Stack } from '@mui/material'

type IProps = React.PropsWithChildren<{}>

export default function ModalBody(props: IProps) {
    return (
        <Stack direction="column" spacing={2} mb={6} overflow={'auto'} flex={1} maxHeight={'100%'} pt={1}>
            {props.children}
        </Stack>
    )
}

import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { useQuery } from 'react-query'
import {IAgoraRTCClient} from "agora-rtc-react"

type IStore = {
    connection: {
        appId: string;
        rtcClient: IAgoraRTCClient | null
        rtcToken: string
        channelName: string
        ownUserId: number | null;
        setAppId(rtcClient: IStore['connection']['appId']): void
        setRtcClient(rtcClient: IStore['connection']['rtcClient']): void
        setRtcToken(rtcToken: IStore['connection']['rtcToken']): void
        setChannelName(channelName: IStore['connection']['channelName']): void
        setOwnUserId(ownUserId: IStore['connection']['ownUserId']): void;
    }
    controls: {
        isMicrophone: boolean
        isCamera: boolean
        isScreenRecord: boolean
        isCall: boolean
        setIsMicrophone(isMicrophone: IStore['controls']['isMicrophone']): void
        setIsCamera(isCamera: IStore['controls']['isCamera']): void
        setIsScreenRecord(isScreenRecord: IStore['controls']['isScreenRecord']): void
        onCallEnd(): void
    }
    weiget: {
        position: { x: number; y: number }
        setPosition(x: number, y: number): void
    }
    grid: {
        orders: {[key: string]: number} // {userId: Date.now}
        setOrders(orders: IStore['grid']['orders']): void;
    }
}

const defaultConnection: Pick<IStore['connection'], 'appId' | 'rtcClient' | 'rtcToken' | 'channelName' | 'ownUserId'> = {
    appId: '',
    rtcClient: null,
    rtcToken: '',
    channelName: '',
    ownUserId: null,
}

const defaultControls: Pick<IStore['controls'], 'isMicrophone' | 'isCamera' | 'isScreenRecord' | 'isCall'> = {
    isMicrophone: false,
    isCamera: false,
    isScreenRecord: false,
    isCall: false,
}

export const useMeetingCallStore = create<IStore>()(
        (set, get, store) => ({
            connection: {
                ...defaultConnection,
                setAppId(appId) {
                    return set(store => ({ ...store, connection: { ...store.connection, appId } }))
                },
                setRtcClient(rtcClient) {
                    return set(store => ({ ...store, connection: { ...store.connection, rtcClient } }))
                },
                setRtcToken(rtcToken) {
                    return set(store => ({ ...store, connection: { ...store.connection, rtcToken } }))
                },
                setChannelName(channelName) {
                    return set(store => ({ ...store, connection: { ...store.connection, channelName } }))
                },
                setOwnUserId(ownUserId) {
                    return set(store => ({ ...store, connection: { ...store.connection, ownUserId } }))
                }
            },
            controls: {
                ...defaultControls,
                setIsMicrophone(isMicrophone) {
                    set(store => ({ ...store, controls: { ...store.controls, isMicrophone } }))
                },
                setIsCamera(isCamera) {
                    set(store => ({ controls: { ...store.controls, isCamera } }))
                },
                setIsScreenRecord(isScreenRecord) {
                    set(store => ({ controls: { ...store.controls, isScreenRecord } }))
                },
                onCallEnd() {
                    set(store => ({ connection: {...store.connection, ...defaultConnection}, controls: {...store.controls, ...defaultControls}, grid: {...store.grid, orders: {}}}))
                }
            },
            weiget: {
                position: { x: 0, y: 0 },
                setPosition(x, y) {
                    set(store => ({ weiget: { ...store.weiget, position: { x, y } } }))
                }
            },
            grid: {
                orders: {},
                setOrders(orders) {
                    set(store => ({ grid: { ...store.grid, orders } }))
                },
            }
        }),
       
)

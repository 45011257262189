import { Button, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { ISocketRequest, SocketAction } from "api/ws/ws";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody/ModalBody";
import ModalFooter from "components/Modal/ModalFooter/ModalFooter";
import ModalHeader from "components/Modal/ModalHeader/ModalHeader";
import { Form, Formik, FormikHelpers } from "formik";
import { useWebSocketContext } from "providers/WebSocketProvider";
import React from "react";
import { useNavigate } from "react-router";
import { ReadyState } from "react-use-websocket";
import { ROLES, ROLE_OPTIONS } from "store/user-profile";
import { useWorkspaceStore } from "store/workspace";
import { checkLogin, checkPassword } from "utils/helpers";

type IProps = {
    open: boolean;
    onClose(): void;
}

type IInitialValues = {
    nickname: string;
}

export default React.memo(function ModalUserConnectCall(props: IProps) {
    const navigate = useNavigate();
    const {workspace} = useWorkspaceStore();
    const {readyState, lastJsonMessage, sendJsonMessage} = useWebSocketContext();

    const initialValues: IInitialValues = {nickname: ''};

    const handleClose = () => {
        navigate(`/${workspace?.linkWorkspace}`)
        // props.onClose();
    }

    return (
        <Modal open={props.open}>
            <Formik
                initialValues={initialValues}
                validate={values => {
                    const errors: Partial<IInitialValues> & Partial<{"roleId": string}> = {};

                    if (values.nickname.length === 0) errors.roleId = "Обязательное поле"
                
                    return errors;
                }}
                onSubmit={(values: IInitialValues, formikHelpers: FormikHelpers<IInitialValues>) => {
                    if (readyState !== ReadyState.OPEN) return console.error(values);

                    // navigate(`/${workspace?.linkWorkspace}/sign-up`)
                    // sendJsonMessage<ISocketRequest>({
                    //     action: SocketAction.CREATE_SERVICE_ACCOUNT,
                    //     payload: {
                    //         workspaceId: workspace.id,
                    //         login: values.login,
                    //         password: values.password,
                    //         roleId: Number(values.roleId),
                    //     }
                    // });
                    // handleClose();
                }}>
                {({
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    isValid,
                    dirty,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <ModalHeader
                            title="Подключение к звонку"
                            subTitle=""
                        />
                        <ModalBody>
                            <Stack direction={"row"} spacing={2}>
                                <Typography>
                                    Для подключения к звонку <br />
                                    вам необходимо зарегистрироваться в системе
                                </Typography>
                                {/* <TextField
                                    name="nickname"
                                    label="Введите имя"
                                    variant="standard"
                                    value={values.nickname}
                                    error={touched.nickname && !!errors.nickname}
                                    helperText={touched.nickname && errors.nickname}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                /> */}
                            </Stack>
                        </ModalBody>
                        <ModalFooter>
                            {/* <Button 
                                autoFocus
                                variant="outlined"
                                onClick={handleClose}
                            >
                                Вход
                            </Button> */}
                            <Button 
                                // disabled={!(isValid && dirty)}
                                variant="outlined" 
                                type="submit"
                                onClick={() => navigate(`/${workspace?.linkWorkspace}/sign-up`)}
                            >
                                Зарегистрироваться
                            </Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>    
        </Modal>
    )
});
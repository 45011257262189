import React from "react";
import ItemWindow from "../components/UserContainer/UserContainer";
import { Avatar, Stack, Typography } from "@mui/material";
import ItemNickname from "../components/Nickname/Nickname";
import { ICameraVideoTrack, ILocalAudioTrack, LocalUser, RemoteUser, useVolumeLevel } from "agora-rtc-react";
import { useUserContactsStore } from "store/user-contacts";
import { useMeetingCallStore } from "store/meeting-call";
import UserContainer from "../components/UserContainer/UserContainer";

type IProps = React.PropsWithChildren<{
    uid: number;
    hasVideo: boolean;
    hasAudio: boolean;
    cameraOn: boolean;
    micOn: boolean;
    videoTrack: ICameraVideoTrack | null;
    audioTrack: ILocalAudioTrack | null;
}>

const sxAvatar: React.CSSProperties = {width: 100, height: 100}; // соотношение сторон у квадарата

export default React.memo(function AgoraLocalUser(props: IProps) {
    const {userContacts} = useUserContactsStore();
    const {controls} = useMeetingCallStore();

    const itemUser = userContacts.find(item => item.id === props.uid);
    const volumeLevel = useVolumeLevel(props.audioTrack || undefined);
    const isSpeak = volumeLevel >= 0.5 && controls.isMicrophone;

    return (
        <UserContainer isSpeak={isSpeak}>
            {props.hasVideo 
                ? <LocalUser 
                    cameraOn={controls.isCamera} 
                    micOn={controls.isMicrophone} 
                    videoTrack={props.videoTrack}
                    audioTrack={props.audioTrack}
                    playAudio={controls.isMicrophone}
                    playVideo={controls.isCamera}
                    style={{height: '100%', width: '100%', transform: 'scale(-1, 1)'}}
                  /> 
                : <Avatar src={itemUser?.urlAvatar} alt={itemUser?.nickname || itemUser?.login} sx={{bgcolor: itemUser?.bgColorAvatar, ...sxAvatar}} />}
            
            <ItemNickname hasAudio={props.hasAudio}>
                {itemUser?.nickname || itemUser?.login} (Вы)
            </ItemNickname>
        </UserContainer>
    )
});
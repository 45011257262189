import AgoraRTC, { AgoraRTCProvider, useRTCClient, IAgoraRTCClient } from "agora-rtc-react";
import { getAgoraRtcRtmTokensOfVideoCallApi } from "api/agora/agora";
import AgoraVideoCallModule from "modules/AgoraVideoCallModule/AgoraVideoCallModule";
import { enqueueSnackbar } from "notistack";
import React from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router";
import { useMeetingCallStore } from "store/meeting-call";
import { useAuthStore } from "store/auth";
import { useUserProfileStore } from "store/user-profile";
import { useSearchParams } from "react-router-dom";
import { IShopExtensionAgora, SHOP_EXTENSION, useShopExtensionsStore } from "store/shop-extensions";

type IProps = React.PropsWithChildren<{}>;

export default function AgoraProvider(props: IProps) {
    const {channel} = useParams();
    const {userId} = useAuthStore();
    const {shopExtensions} = useShopExtensionsStore();
    const [searchParams, setSearchParams] = useSearchParams();
    const ownUserId = searchParams.get('ownUserId');

    const {connection, controls, weiget} = useMeetingCallStore();
    const client: IAgoraRTCClient = useRTCClient(AgoraRTC.createClient({ codec: "vp8", mode: "rtc",  }));

    const itemShop = React.useMemo(() => shopExtensions.find((item) => item.id === SHOP_EXTENSION.MEETING_CALL_AGORA), [shopExtensions]) as IShopExtensionAgora;

    const { isSuccess, mutate, isLoading } = useMutation(
        ['agora'],
        () => getAgoraRtcRtmTokensOfVideoCallApi(connection.channelName, userId!),
        {
            onSuccess: (data) => {
                connection.setRtcToken(data.rtcToken);
                connection.setAppId(itemShop?.data?.agoraAppId || '');
                if (!connection.rtcClient) connection.setRtcClient(client);
                if (channel) connection.setChannelName(channel);
                if (ownUserId) connection.setOwnUserId(Number(ownUserId));
            },
            onError: (data: any) => {
                enqueueSnackbar(data.response.data.message, {
                    variant:
                        data.response.data.type === 'ERROR' ? 'error' : 'info'
                })
            }
        }
    );

    React.useEffect(() => {
        if (itemShop) mutate();
    }, [channel, userId, itemShop]);


    if (!connection.rtcToken || !connection.channelName || !connection.rtcClient) return <>{props.children}</> 

    return (
        <AgoraRTCProvider client={connection.rtcClient}>
            <AgoraVideoCallModule />
            {props.children}
        </AgoraRTCProvider>
    )
}
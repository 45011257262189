import { Box, Button, Grid, Link, Paper, Stack, TextField, Typography } from '@mui/material'
import { Formik, FormikHelpers, FormikValues } from 'formik'
import { useEffect, useState } from 'react'
import { Link as LinkRouter, useNavigate, useParams } from 'react-router-dom'
import isEmail from 'validator/lib/isEmail'
import { Input } from '@mui/material'
import moment from 'moment'
import { verifyEmailUserApi } from 'api/auth/auth'
import VerificationInput from 'react-verification-input'
import LayoutAuth from 'components/Layouts/LayoutAuth/LayoutAuth'
import styles from './VerifyEmailPage.module.scss'

type IInitialValues = {
    verifyCode: string
}

export default function VerifyEmailPage() {
    const navigate = useNavigate()
    const { email, verifyEmailCode, linkWorkspace } = useParams<{ email: string; verifyEmailCode: string; linkWorkspace: string }>() // Если accept, то почта уже верефицирована, тоесть она не нужна для регистрации

    const [verifyCode, setVerifyCode] = useState<string>(verifyEmailCode || '')

    const [time, setTime] = useState('00:59')

    const onSubmit = async () => {
        try {
            const res = await verifyEmailUserApi(email!, verifyCode)
            navigate(`/${linkWorkspace}/sign-in`)
        } catch (error) {}
    }

    useEffect(() => {
        if (time === '00:00') return
        const id = setTimeout(() => {
            const newTime = moment(`12:${time}`, 'HH:mm:ss').add(-1, 'seconds').format('mm:ss')
            setTime(newTime)
        }, 1000)

        // return () => {
        //     setTime("00:59");
        //     clearInterval(id);
        // }
    }, [time])
    console.log(email)

    return (
        <Box minWidth="100vw" minHeight="100vh" bgcolor="rgba(178, 170, 189, 1)" display="flex" alignItems="center" justifyContent="center">
            <Paper sx={{ overflow: 'hidden', borderRadius: 3, backgroundColor: 'rgba(255, 248, 249, 1)', maxWidth: 454, p: 8 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} textAlign="center">
                        <Typography variant="h5">Подтверждение почты</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">На почту {email} был отправлен код, его нужно ввести ниже</Typography>
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <Typography variant="subtitle1" fontWeight={500}>
                            Введите код
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <VerificationInput
                            placeholder="-"
                            validChars="0-9"
                            inputProps={{ inputMode: 'numeric' }}
                            classNames={{
                                container: styles.Container,
                                character: styles.Character,
                                characterInactive: styles.CharacterInactive,
                                characterSelected: styles.CharacterSelected
                            }}
                            value={verifyCode}
                            onChange={setVerifyCode}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} textAlign="center">
                                <Button variant="text" color="primary" href="/sign-in" disabled={time !== '00:00'}>
                                    Отправить код повторно {time}
                                </Button>
                            </Grid>

                            <Grid item xs={12} textAlign="center">
                                <Button variant="contained" color="primary" type="submit" onClick={onSubmit} disabled={verifyCode.length < 6}>
                                    Вход
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    )
}

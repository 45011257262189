import React from 'react'
import { Box, ButtonBase, SxProps, Theme } from '@mui/material'
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined'
import { styled } from '@mui/system'

const AvatarBox = styled(Box)({
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderRadius: '10px',
    '&:hover .hoverDiv': {
        transform: 'translateY(0)'
    }
})

const HoverDiv = styled(Box)({
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '30%',
    maxHeight: '76px',
    opacity: 0.4,
    backgroundColor: '#36072C',
    color: 'white',
    transform: 'translateY(100%)',
    transition: 'transform 0.3s linear',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px'
})

type IProps = {
    sx?: SxProps<Theme>
    srcImage: string | null
    onLoaded(imageOfBase64: string): void
}

export default function UploadPreviewImage(props: IProps) {
    const [file, setFile] = React.useState(null)
    const [fileDataURL, setFileDataURL] = React.useState<string | undefined>()
    const imageMimeType = /image\/(png|jpg|jpeg|webp|svg)/i

    const changeHandler = (e: any) => {
        const file = e.target.files[0]
        if (!file.type.match(imageMimeType)) {
            alert('Image mime type is not valid')
            return
        }
        setFile(file)
    }

    React.useEffect(() => {
        setFileDataURL(props.srcImage || '')
    }, [props.srcImage])

    React.useEffect(() => {
        let fileReader: FileReader,
            isCancel = false
        if (file) {
            fileReader = new FileReader()
            fileReader.onload = e => {
                if (!e.target) return
                const { result } = e.target
                if (result && !isCancel) {
                    setFileDataURL(result as string)
                    props.onLoaded(result as string)
                }
            }
            fileReader.readAsDataURL(file)
        }
        return () => {
            isCancel = true
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort()
            }
        }
    }, [file])

    return (
        <AvatarBox component={ButtonBase}>
            <label
                style={{
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    cursor: 'pointer',
                    maxHeight: '385px'
                }}
            >
                <img
                    style={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center'
                    }}
                    src={fileDataURL || props.srcImage || ''}
                    loading="lazy"
                />
                <input
                    type={'file'}
                    style={{ display: 'none' }}
                    name="urlAvatar"
                    // accept=".jpg, .png, .svg"
                    accept="image/*"
                    onChange={changeHandler}
                />
                <HoverDiv className="hoverDiv">
                    <PhotoCameraOutlinedIcon />
                </HoverDiv>
            </label>
        </AvatarBox>
    )
}

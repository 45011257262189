import { Box, Stack } from "@mui/material";
import React from "react";
import IconEqSound from "./IconEqSound/IconEqSound";

type IProps = React.PropsWithChildren<{
    isSpeak: boolean;
}>

export default React.memo(function UserContainer(props: IProps) {
    return (
        <Box 
            position={"relative"} 
            display={"flex"} 
            alignItems={"center"} 
            justifyContent={"center"}
            width={'100%'} 
            height={'100%'} 
            sx={{background: '#3c4043', transition: 'all .100s ease'}}
            borderRadius={1}
            overflow={'hidden'}
            boxShadow={props.isSpeak ? `0 0 0 3px #0f70e6` : 'none'}
        >
            {props.isSpeak && (
                <Stack justifyContent={"center"} alignItems={"center"} position={"absolute"} right={16} top={16} sx={{backgroundColor: '#0f70e6'}} borderRadius={'6px'} width={28} height={28} zIndex={999}>
                    <IconEqSound />
                </Stack>
            )}
           
            {props.children}
        </Box>
    )
})
import TabContext from '@mui/lab/TabContext'
import { Box, Tab, Tabs, Typography } from '@mui/material'
import { LinkTab } from 'components/LinkTab/LinkTab'
import React from 'react'
import { Outlet, useLocation, useNavigate, useNavigation, useParams } from 'react-router'
import { useWorkspaceStore } from 'store/workspace'

const URL = {
    URL_WORKSPACE_SETTINGS: 'workspace-settings',
    URL_ADMINISTRATION_USERS: 'workspace-settings/administration-users',
    URL_SHOP: 'workspace-settings/shop'
}

export default function LayoutWorkspaceSetting() {
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const { linkWorkspace } = useParams<{ linkWorkspace: string }>()
    const [value, setValue] = React.useState(pathname)

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        event.preventDefault()
        setValue(newValue)
        navigate(newValue)
    }

    return (
        <Box width={'100%'} p="28px 80px" bgcolor="rgba(255, 255, 255, 1)">
            <Box pl={9} pr={9} borderBottom="1px solid rgba(231, 224, 232, 1)">
                <Typography variant="h4" fontSize={28} mb={1.5}>
                    Настройки
                </Typography>
                <TabContext value={value}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="Общая информация" value={`/${linkWorkspace}/${URL.URL_WORKSPACE_SETTINGS}`} sx={{ pl: 0, pr: 0, mr: 2 }} />
                        <Tab label="Администрирование пользователей" value={`/${linkWorkspace}/${URL.URL_ADMINISTRATION_USERS}`} sx={{ pl: 0, pr: 0, mr: 2, ml: 2 }} />
                        <Tab label="Магазин" value={`/${linkWorkspace}/${URL.URL_SHOP}`} sx={{ pl: 0, pr: 0, ml: 2 }} />
                    </Tabs>
                </TabContext>
            </Box>
            <Box>
                <Outlet />
            </Box>
        </Box>
    )
}

import { Avatar, Box, IconButton, Paper, Stack, Typography, useTheme } from "@mui/material"
import { IAgoraRTCRemoteUser, useClientEvent, useJoin, useLocalCameraTrack, useLocalMicrophoneTrack, usePublish, useRemoteAudioTracks, useRemoteUsers, useRemoteVideoTracks, useVolumeLevel } from "agora-rtc-react";
import { useMeetingCallStore } from "store/meeting-call";
import { useUserContactsStore } from "store/user-contacts";
import { useUserProfileStore } from "store/user-profile";

import React from "react";
import AgoraVideoCallWidget from "./AgoraVideoCallWidget/AgoraVideoCallWidget";
import { useAuthStore } from "store/auth";
import AgoraVideoCallDesktop from "./AgoraVideoCallDesktop/AgoraVideoCallDesktop";

import {DndContext} from '@dnd-kit/core';
import { matchPath, useLocation, useNavigate } from "react-router";
import { useWorkspaceStore } from "store/workspace";
import ModalUserConnectCall from "./ModalUserConnectCall/ModalUserConnectCall";
import ModalPriviewJoinConnect from "./ModalPriviewJoinConnect/ModalPriviewJoinConnect";

type IProps = React.PropsWithChildren<{}>;

export default function AgoraVideoCallModule(props: IProps) {
    const { pathname } = useLocation();
    const {userId} = useAuthStore();
    const {workspace} = useWorkspaceStore();
    const {connection, controls, weiget, grid} = useMeetingCallStore();
    const {userProfile, loadingUserProfile} = useUserProfileStore();
    const [timestamp, setTimestamp] = React.useState(Date.now());

    const [openUnknowUserModal, setOpenUnknowUserModal] = React.useState<boolean>(false);
    const [openPreviewJoinConnactModal, setOpenPreviewJoinConnactModal] = React.useState<boolean>(true);

    React.useEffect(() => { // если пользователь не авторизован и нет у него профиля, то отобразить окно для ввода "Имя" для текущего подключения
        if (loadingUserProfile) return;
        if (!userProfile) setOpenUnknowUserModal(true);
        else setOpenUnknowUserModal(false);
    }, [userProfile, loadingUserProfile])

    const isMeetingCallPage = !!matchPath(`/${workspace?.linkWorkspace}/meeting/call/${connection.channelName}`, pathname);

    const { isLoading: isLoadingMic, localMicrophoneTrack } = useLocalMicrophoneTrack(!!controls.isMicrophone);
    const { isLoading: isLoadingCam, localCameraTrack } = useLocalCameraTrack(!!controls.isCamera);
    const remoteUsers = useRemoteUsers();
    const { audioTracks } = useRemoteAudioTracks(remoteUsers);
    const { videoTracks } = useRemoteVideoTracks(remoteUsers);

    // React.useEffect(() => { // initial
    //     controls.setIsCamera(Boolean(localCameraTrack?.enabled));
    //     controls.setIsMicrophone(Boolean(localMicrophoneTrack?.enabled));
    // }, [localCameraTrack?.enabled, localMicrophoneTrack?.enabled]);

    React.useEffect(() => { // sync
        localCameraTrack?.setEnabled(Boolean(controls.isCamera));
        localMicrophoneTrack?.setEnabled(Boolean(controls.isMicrophone));
    }, [
        controls.isCamera,
        controls.isMicrophone,
    ]);

    const {isConnected, data} = useJoin({
        uid: Number(userId),
        appid: connection.appId,
        channel: connection.channelName,
        token: connection.rtcToken,
    });

    const {error, isLoading} = usePublish([
        localMicrophoneTrack, 
        localCameraTrack
    ]);
    

    

    
    audioTracks.forEach((track) => track.play()); // обязательно нужно оставить иначе звук будет только при вкл. камере
    // videoTracks.map((track) => track.play());
    const deviceLoading = isLoadingMic || isLoadingCam;
    // if (deviceLoading) return <div>Loading devices...</div>;

    if (openUnknowUserModal && !loadingUserProfile) {
        return (
            <ModalUserConnectCall 
                open={openUnknowUserModal} 
                onClose={() => setOpenUnknowUserModal(false)} 
            />
        )
    }


    return (
        <DndContext autoScroll={false}>
            <ModalPriviewJoinConnect open={openPreviewJoinConnactModal} onClose={() => setOpenPreviewJoinConnactModal(false)} />

            {isMeetingCallPage ? (
                <AgoraVideoCallDesktop localCameraTrack={localCameraTrack} localMicrophoneTrack={localMicrophoneTrack} remoteUsers={remoteUsers} />
            ) : (
                <AgoraVideoCallWidget localCameraTrack={localCameraTrack} localMicrophoneTrack={localMicrophoneTrack} remoteUsers={remoteUsers} />
            )}
        </DndContext>
    )
    
}
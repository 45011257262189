import React from 'react'
import { Avatar, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { checkLogin, getFullUrlImage } from 'utils/helpers'
import EditIcon from '@mui/icons-material/Edit'
import ModalEditEmail from 'pages/ProfileSettingsPage/ModalEditEmail/ModalEditEmail'
import ModalEditPassword from 'pages/ProfileSettingsPage/ModalEditPassword/ModalEditPassword'
import moment from 'moment/moment'
import { getIconByRole, IUserProfile, ROLE_ICONS, ROLE_OPTIONS, ROLES, useUserProfileStore } from 'store/user-profile'
import UploadPreviewImage from 'components/UploadPreviewImage/UploadPreviewImage'
import { useUserProfile } from 'hooks/useUserProfileHook'
import { useWorkspaceStore } from 'store/workspace'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { Box } from '@mui/system'
import ModalEditLogin from 'pages/ProfileSettingsPage/ModalEditLogin/ModalEditLogin'
import { Form, Formik, FormikHelpers } from 'formik'
import { useAuthStore } from 'store/auth'
import { useWebSocketContext } from 'providers/WebSocketProvider'
import { ReadyState } from 'react-use-websocket'
import { ISocketRequest, SocketAction } from 'api/ws/ws'
import { IItemChatUserOfListContacts } from 'store/user-contacts'

type IProps = React.PropsWithChildren<{
    userProfile: IUserProfile | IItemChatUserOfListContacts | null
    editable: boolean
    title: string
    subtitle: string
}>

type IInitialValues = Pick<IUserProfile, 'login' | 'password' | 'firstname' | 'middlename' | 'lastname' | 'email' | 'urlAvatar' | 'nickname' | 'birthday' | 'status'> & {
    workspaceAvatarFile: string
}

export default React.memo(function Profile(props: IProps) {
    const { userId } = useAuthStore()
    const { workspace } = useWorkspaceStore()
    const { getUserProfileApi, updateUserProfileImageApi } = useUserProfile(workspace?.id!, Number(userId))
    const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocketContext()

    const [fieldType, setFieldType] = React.useState<string>('text')
    const [openPasswordEdit, setOpenPasswordEdit] = React.useState(false)
    const [openEmailEdit, setOpenEmailEdit] = React.useState(false)
    const [openLoginEdit, setOpenLoginEdit] = React.useState(false)

    const initialValues: IInitialValues = {
        login: props.userProfile?.login!,
        password: props.userProfile?.password!,
        firstname: props.userProfile?.firstname!,
        middlename: props.userProfile?.middlename!,
        lastname: props.userProfile?.lastname!,
        email: props.userProfile?.email!,
        urlAvatar: getFullUrlImage(props.userProfile?.urlAvatar),
        nickname: props.userProfile?.nickname!,
        birthday: props.userProfile?.birthday ? moment(props.userProfile.birthday).format('DD.MM.YYYY') : '',
        status: props.userProfile?.status ?? '',
        workspaceAvatarFile: ''
    }

    const onSubmit = (values: IInitialValues, formikHelpers: FormikHelpers<IInitialValues>) => {
        if (readyState !== ReadyState.OPEN) return
        sendJsonMessage<ISocketRequest>({
            action: SocketAction.UPDATE_USER_PROFILE_OF_WORKSPACE,
            payload: {
                firstname: values.firstname,
                middlename: values.middlename,
                lastname: values.lastname,
                nickname: values.nickname,
                birthday: moment(values.birthday, 'DD.MM.YYYY', true).isValid() ? moment(values.birthday, 'DD.MM.YYYY').format('YYYY-MM-DD') : values.birthday,
                status: values.status
            }
        })
        setFieldType('text')
    }

    const handleFocus = () => {
        if (!props.editable) return
        setFieldType('date')
    }

    const handleOnPasswordEdit = () => setOpenPasswordEdit(true)
    const handleOffPasswordEdit = () => setOpenPasswordEdit(false)

    const handleOnEmailEdit = () => setOpenEmailEdit(true)
    const handleOffEmailEdit = () => setOpenEmailEdit(false)

    const handleOnLoginEdit = () => setOpenLoginEdit(true)
    const handleOffLoginEdit = () => setOpenLoginEdit(false)

    const label = React.useMemo(
        () =>
            Object.entries(ROLE_OPTIONS)
                .find(([id]) => Number(id) === props.userProfile?.roleId)
                ?.at(1),
        [props.userProfile?.roleId]
    )

    const Icon = React.useMemo(() => getIconByRole(props.userProfile?.roleId!), [props.userProfile?.roleId])

    return (
        <Grid padding="64px 56px">
            <Grid container gap={7} mb={7}>
                <Grid item width="310px">
                    <Stack height={'100%'} position={'relative'} overflow={'hidden'} borderRadius={4}>
                        {props.editable ? (
                            <UploadPreviewImage
                                sx={{
                                    width: '100%'
                                }}
                                srcImage={getFullUrlImage(initialValues.urlAvatar)}
                                onLoaded={imageOfBase64 => {
                                    updateUserProfileImageApi(Number(workspace?.id), Number(userId), imageOfBase64)
                                }}
                            />
                        ) : (
                            <Avatar
                                src={getFullUrlImage(initialValues.urlAvatar)}
                                variant="square"
                                sx={{
                                    margin: '0 auto',
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: '16px'
                                }}
                            />
                        )}
                    </Stack>
                </Grid>
                <Grid item width="310px" display="flex" gap={3} flexDirection="column" justifyContent="space-between" minHeight="340px">
                    <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                        <Typography variant="h5">{props.title}</Typography>
                        <ErrorOutlineOutlinedIcon sx={{ color: '#844C72', cursor: 'pointer' }} />
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                        Ваша роль: {Icon} {label}
                    </Box>
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="outlined-login">Логин</InputLabel>
                        <OutlinedInput
                            sx={{ p: 0 }}
                            value={initialValues.login || '-'}
                            inputProps={{
                                readOnly: true
                            }}
                            endAdornment={
                                props.editable && (
                                    <InputAdornment position="end">
                                        <Tooltip title="Редактировать">
                                            <span>
                                                <IconButton onClick={handleOnLoginEdit}>
                                                    <EditIcon />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }
                            label="Логин"
                        />
                        {props.editable && <ModalEditLogin open={openLoginEdit} onClose={handleOffLoginEdit} />}
                    </FormControl>
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="outlined-login">Почта</InputLabel>
                        <OutlinedInput
                            sx={{ p: 0 }}
                            value={initialValues.email ?? '-'}
                            inputProps={{
                                readOnly: true
                            }}
                            endAdornment={
                                props.editable && (
                                    <InputAdornment position="end">
                                        <Tooltip title="Редактировать">
                                            <span>
                                                <IconButton onClick={handleOnEmailEdit}>
                                                    <EditIcon />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }
                            label="Почта"
                        />
                        <ModalEditEmail open={openEmailEdit} onClose={handleOffEmailEdit} />
                    </FormControl>
                    {props.editable && (
                        <>
                            <FormControl variant="outlined">
                                <InputLabel htmlFor="outlined-password">Пароль</InputLabel>
                                <OutlinedInput
                                    sx={{ p: 0 }}
                                    id="outlined-password"
                                    value={initialValues.password?.replace(/./g, '*')}
                                    inputProps={{
                                        readOnly: true
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <Tooltip title="Редактировать">
                                                <span>
                                                    <IconButton onClick={handleOnPasswordEdit}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </InputAdornment>
                                    }
                                    label="Пароль"
                                />
                                <ModalEditPassword open={openPasswordEdit} onClose={handleOffPasswordEdit} />
                            </FormControl>
                            <Stack display="flex" alignItems="center" justifyContent="center">
                                <Button variant="contained" color="error">
                                    Удалить профиль
                                </Button>
                            </Stack>
                        </>
                    )}
                </Grid>
            </Grid>
            <Grid>
                <Box display="flex" justifyContent="center">
                    <Typography variant="h5" mb={3}>
                        {props.subtitle}
                    </Typography>
                </Box>
                <Formik
                    initialValues={initialValues}
                    validate={values => {
                        const errors: Partial<IInitialValues> = {}

                        if (checkLogin(values.login)) errors.login = checkLogin(values.login)

                        return errors
                    }}
                    onSubmit={onSubmit}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, handleReset, setFieldValue, isSubmitting, isValid, dirty }) => {
                        return (
                            <Form>
                                <Grid container gap={3} justifyContent="space-between" mb={3}>
                                    <Grid width="48%">
                                        <TextField
                                            label="Псевдоним в пространстве"
                                            name="nickname"
                                            value={values.nickname}
                                            error={touched.nickname && !!errors.nickname}
                                            onChange={handleChange}
                                            variant="outlined"
                                            size="medium"
                                            fullWidth
                                            inputProps={{
                                                readOnly: !props.editable
                                            }}
                                        />
                                    </Grid>
                                    <Grid width="48%">
                                        <TextField
                                            label="Статус"
                                            name="status"
                                            value={values.status}
                                            error={touched.status && !!errors.status}
                                            onChange={handleChange}
                                            variant="outlined"
                                            size="medium"
                                            fullWidth
                                            inputProps={{
                                                readOnly: !props.editable
                                            }}
                                        />
                                    </Grid>
                                    <Grid width="48%">
                                        <TextField
                                            label="Имя"
                                            name="firstname"
                                            value={values.firstname}
                                            error={touched.firstname && !!errors.firstname}
                                            onChange={handleChange}
                                            variant="outlined"
                                            size="medium"
                                            fullWidth
                                            inputProps={{
                                                readOnly: !props.editable
                                            }}
                                        />
                                    </Grid>
                                    <Grid width="48%">
                                        <TextField
                                            label="Фамилия"
                                            name="lastname"
                                            value={values.lastname}
                                            error={touched.lastname && !!errors.lastname}
                                            onChange={handleChange}
                                            variant="outlined"
                                            size="medium"
                                            fullWidth
                                            inputProps={{
                                                readOnly: !props.editable
                                            }}
                                        />
                                    </Grid>
                                    <Grid width="48%">
                                        <TextField
                                            label="Отчество"
                                            name="middlename"
                                            value={values.middlename}
                                            error={touched.middlename && !!errors.middlename}
                                            onChange={handleChange}
                                            variant="outlined"
                                            size="medium"
                                            fullWidth
                                            inputProps={{
                                                readOnly: !props.editable
                                            }}
                                        />
                                    </Grid>
                                    <Grid width="48%">
                                        <TextField
                                            name="birthday"
                                            value={values.birthday}
                                            error={touched.birthday && !!errors.birthday}
                                            onChange={handleChange}
                                            onBlur={event => {
                                                handleBlur(event)
                                                setFieldType('text')
                                            }}
                                            onFocus={handleFocus}
                                            size="medium"
                                            variant="outlined"
                                            label="День рождения"
                                            fullWidth
                                            type={fieldType}
                                            inputProps={{
                                                readOnly: fieldType === 'text' && !props.editable
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                {props.editable && (
                                    <Grid display="flex" alignItems="center" justifyContent="center">
                                        <Button type="submit" color="primary" variant="contained" disabled={!(isValid && dirty)}>
                                            Сохранить
                                        </Button>
                                    </Grid>
                                )}
                            </Form>
                        )
                    }}
                </Formik>
            </Grid>
        </Grid>
    )
})

import styles from './IconEqSound.module.scss';
import classnames from 'classnames';

const cx = classnames.bind(styles);

export default function IconEqSound() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <rect className={cx(styles.IconEqSound, styles.__1)} x="4" y="4" width="3.7" height="8" />
            <rect className={cx(styles.IconEqSound, styles.__2)} x="10.2" y="4" width="3.7" height="16"/>
            <rect className={cx(styles.IconEqSound, styles.__3)} x="16.3" y="4" width="3.7" height="11"/>
        </svg>
    )
}
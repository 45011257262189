import React, { useMemo } from 'react'
import { Box, Dialog, Modal as MuiModal, ModalProps, Paper, Stack } from '@mui/material'
import styles from './Modal.module.scss'
import { styled } from '@mui/system'

type IProps = Omit<ModalProps, 'children'> &
    React.PropsWithChildren<{
        width?: number | string
    }>

export default function Modal(props: IProps) {
    const style = useMemo(() => ({ width: props.width }), [props.width])

    return (
        <MuiModal
            {...props}
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: '16px'
                }
            }}
        >
            <Paper className={styles.Modal} style={style}>
                <Stack p={8} borderRadius={2} maxHeight={'95vh'}>
                    {props.children}
                </Stack>
            </Paper>
        </MuiModal>
    )
}

import { Stack, Typography } from "@mui/material";
import React from "react";

import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';

type IProps = React.PropsWithChildren<{
    hasAudio: boolean;
}>

export default React.memo(function Nickname(props: IProps) {
    return (
        <Stack direction={"row"} spacing={0.5} alignItems={"center"} sx={{background: 'rgba(0,0,0, .3)'}} position={"absolute"} bottom={0} left={0} color={'white'} padding={0.25}>
            {props.hasAudio ? (<MicIcon fontSize={'small'} />) : (<MicOffIcon fontSize={'small'} color="error" />)}
            <Typography alignItems={"center"}>
                {props.children}
            </Typography>
        </Stack>
    )
})
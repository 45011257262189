import { ISocketRequest, SocketAction } from 'api/ws/ws'
import { useWebSocketContext } from 'providers/WebSocketProvider'
import React from 'react'
import { ReadyState } from 'react-use-websocket'
import { IUserProfile, useUserProfileStore } from 'store/user-profile'

type IProps = {
    workspaceId: number | null
    userId: number
}

type IInitialValues = Pick<
    IUserProfile,
    | 'login'
    | 'password'
    | 'firstname'
    | 'middlename'
    | 'lastname'
    | 'email'
    | 'verifyEmailCode'
    | 'urlAvatar'
    | 'nickname'
    | 'birthday'
>

export const useUserProfile = (workspaceId: number, userId: number) => {
    const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocketContext()
    const { setUserProfile, userProfile, loadingUserProfile, setLoadingUserProfile } = useUserProfileStore()

    const getUserProfileApi = (workspaceId: number, userId: number) => {
        if (readyState !== ReadyState.OPEN) return
        setLoadingUserProfile(true);
        sendJsonMessage<ISocketRequest>({
            // запросим текущий профиль
            action: SocketAction.GET_USER_PROFILE_OF_WORKSPACE,
            payload: {
                workspaceId: workspaceId,
                userId: userId
            }
        }, true)
    }

    const updateUserProfileImageApi = (
        workspaceId: number,
        userId: number,
        imageOfBase64: string
    ) => {
        if (readyState !== ReadyState.OPEN) return
        sendJsonMessage<ISocketRequest>({
            action: SocketAction.UPDATE_USER_PROFILE_OF_WORKSPACE,
            payload: {
                workspaceId: workspaceId,
                userId: userId,
                roleId: Number(userProfile?.roleId),
                workspaceAvatarFile: imageOfBase64
            }
        })
    }

    const updateUserProfileApi = (
        workspaceId: number,
        userId: number,
        values: IInitialValues
    ) => {
        if (readyState !== ReadyState.OPEN) return
        sendJsonMessage<ISocketRequest>({
            action: SocketAction.UPDATE_USER_PROFILE_OF_WORKSPACE,
            payload: {
                workspaceId: workspaceId,
                userId: userId,
                roleId: Number(userProfile?.roleId),
                ...values
            }
        })
    }

    React.useEffect(() => {
        // получение ответов
        if (!lastJsonMessage) return
        switch (lastJsonMessage.action) {
            case SocketAction.GET_USER_PROFILE_OF_WORKSPACE: {
                // сохранение текущего профиля
                setUserProfile(lastJsonMessage.payload)
                setLoadingUserProfile(false);
                break
            }
        }
    }, [lastJsonMessage])

    return {
        getUserProfileApi,
        updateUserProfileImageApi,
        updateUserProfileApi
    }
}

import React, { Suspense } from 'react'
import './App.css'
import { BrowserRouter, Navigate, Outlet, Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { useAuthStore } from 'store/auth'
import VerifyEmailPage from 'pages/VerifyEmailPage/VerifyEmailPage'
import LayoutMain from 'components/Layouts/LayoutApp/LayoutMain'
import LayoutAuth from 'components/Layouts/LayoutAuth/LayoutAuth'
import { LinearProgress } from '@mui/material'
import ProfileSettingsPage from 'pages/ProfileSettingsPage/ProfileSettingsPage'
import { useQuery } from 'react-query'
import { getWorkspaceByNameApi } from 'api/workspace/workspace'
import { useWorkspaceStore } from 'store/workspace'
import $ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute'
import { ROLES } from 'store/user-profile'
import LayoutWorkspaceSetting from './components/Layouts/LayoutWorkspaceSetting/LayoutWorkspaceSetting'

const RootPage = React.lazy(() => import('./pages/RootPage/RootPage'))
const LandingPage = React.lazy(() => import('./pages/LandingPage/LandingPage'))
const SignInPage = React.lazy(() => import('./pages/SignInPage/SignInPage'))
const SignUpPage = React.lazy(() => import('./pages/SignUpPage/SignUpPage'))
const RecoveryAccountPage = React.lazy(() => import('./pages/RecoveryAccountPage/RecoveryAccountPage'))
const ResetPasswordPage = React.lazy(() => import('./pages/ResetPasswordPage/ResetPasswordPage'))
const HomePage = React.lazy(() => import('./pages/HomePage/HomePage'))
const ChatTextPage = React.lazy(() => import('./pages/ChatTextPage/ChatTextPage'))
const MeetingPage = React.lazy(() => import('./pages/MeetingPage/MeetingPage'))
const WorkspaceSettingsPage = React.lazy(() => import('./pages/WorkspaceSettingsPage/WorkspaceSettingsPage'))
const WorkspaceAdministrationUsersPage = React.lazy(() => import('./pages/WorkspaceAdministrationUsersPage/WorkspaceAdministrationUsersPage'))
const WorkspaceShopPage = React.lazy(() => import('./pages/WorkspaceShopPage/WorkspaceShopPage'))
const DiskPage = React.lazy(() => import('./pages/DiskPage/DiskPage'))
const CalendarPage = React.lazy(() => import('./pages/CalendarPage/CalendarPage'))
const WorkspaceNotFoundPage = React.lazy(() => import('./pages/WorkspaceNotFoundPage/WorkspaceNotFoundPage'))
const MeetingCallPage = React.lazy(() => import('./pages/MeetingCallPage/MeetingCallPage'))
const MeetingMapPage = React.lazy(() => import('./pages/MeetingMapPage/MeetingMapPage'))
const ControlScreensPage = React.lazy(() => import('./pages/ControlScreensPage/ControlScreensPage'))
const ZoomPage = React.lazy(() => import('./pages/ZoomPage/ZoomPage'))
const ZegoCloudPage = React.lazy(() => import('./pages/ZegoCloudPage/ZegoCloudPage'))

const SuspenseLayout = () => {
    const naviagte = useNavigate()
    const { linkWorkspace } = useParams()
    const { setWorkspace } = useWorkspaceStore()

    const { data, isLoading, error } = useQuery('workspace', () => getWorkspaceByNameApi(linkWorkspace!), {
        onSuccess(data) {
            setWorkspace(data)
        },
        onError(err) {
            naviagte(`/${linkWorkspace}/workspace-not-found`)
        }
    })

    return (
        <Suspense fallback={<LinearProgress color="primary" />}>
            <Outlet />
        </Suspense>
    )
}

function App() {
    const authStore = useAuthStore()

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/zoom" element={<ZoomPage />} />
                <Route path="/:linkWorkspace" element={<SuspenseLayout />}>
                    {/* Слой авторизации */}
                    <Route element={<LayoutAuth />}>
                        <Route path="sign-in" element={<SignInPage />} />
                        <Route path="recovery-account">
                            <Route path=":login" element={<RecoveryAccountPage />} />
                            <Route index element={<RecoveryAccountPage />} />
                        </Route>
                        <Route path="reset-password">
                            <Route path=":accept" element={<ResetPasswordPage />} />
                            <Route index element={<ResetPasswordPage />} />
                        </Route>
                        <Route path="sign-up">
                            <Route path=":accept" element={<SignUpPage />} />
                            <Route index element={<SignUpPage />} />
                        </Route>
                        <Route path="verify-email">
                            <Route path=":email/:verifyEmailCode" element={<VerifyEmailPage />} />
                            <Route path=":email" element={<VerifyEmailPage />} />
                            <Route index element={<VerifyEmailPage />} />
                        </Route>
                    </Route>
                    {/* Слой авторизации */}

                    {/* <Route path="" index element={authStore.isAuth ? <Navigate to={`sign-in`} />} /> */}

                    {/* Слой приложения */}
                    <Route element={<LayoutMain />}>
                        <Route path="home" element={<HomePage />} />
                        <Route path="chat-text">
                            <Route path=":chatId/:chatType" element={<ChatTextPage />} />
                            <Route index element={<ChatTextPage />} />
                        </Route>
                        <Route path="meeting">
                            <Route path="call/:channel" element={<MeetingCallPage />} />
                            <Route path="map/:channel" element={<MeetingMapPage />} />
                            <Route index element={<MeetingPage />} />
                        </Route>
                        <Route
                            path="workspace-settings"
                            element={
                                <$ProtectedRoute roles={[ROLES.OWNER, ROLES.ADMIN]}>
                                    <LayoutWorkspaceSetting />
                                </$ProtectedRoute>
                            }
                        >
                            <Route
                                path="administration-users"
                                element={
                                    <$ProtectedRoute roles={[ROLES.OWNER, ROLES.ADMIN]}>
                                        <WorkspaceAdministrationUsersPage />
                                    </$ProtectedRoute>
                                }
                            />

                            <Route
                                path="shop"
                                element={
                                    <$ProtectedRoute roles={[ROLES.OWNER, ROLES.ADMIN]}>
                                        <WorkspaceShopPage />
                                    </$ProtectedRoute>
                                }
                            />
                            <Route
                                index
                                element={
                                    <$ProtectedRoute roles={[ROLES.OWNER, ROLES.ADMIN]}>
                                        <WorkspaceSettingsPage />
                                    </$ProtectedRoute>
                                }
                            />
                        </Route>
                        <Route path="disk" element={<DiskPage />} />
                        <Route path="chat-calendar" element={<CalendarPage />} />
                        <Route path="profile-settings" element={<ProfileSettingsPage />} />

                        <Route path="control-screens" element={<ControlScreensPage />} />

                        <Route path="zegocloud" element={<ZegoCloudPage />} />
                    </Route>

                    {/* Слой лендинга */}
                    <Route path="" element={<LandingPage />} />
                    {/* Слой лендинга */}

                    {/* Пространство не существует */}
                    <Route path="workspace-not-found" element={<WorkspaceNotFoundPage />} />
                    {/* Пространство не существует */}

                    {/* <Route path="/" index element={<Navigate to={authStore.isAuth ? "/sign-in" : "/"} />} /> */}
                </Route>
                <Route path="/" element={<RootPage />} />
            </Routes>
        </BrowserRouter>
    )
}

export default App

import { IChatType } from 'api/ws/ws'
import { create } from 'zustand'
import { IItemMessageOfTextChat } from './chat-messages'
import { n } from '@fullcalendar/core/internal-common'

export enum DOING_OF_TEXT_CHAT {
    WRITE_TEXT_MESSAGE = 'WRITE_TEXT_MESSAGE',
    WRITE_AUDIO_MESSAGE = 'WRITE_AUDIO_MESSAGE'
}

export type IItemChatUserOfListContacts = {
    id: number
    type: IChatType.PERSONAL
    fullName: string
    password: string | null
    isPasswordCompromised: boolean
    firstname: string | null
    middlename: string | null
    lastname: string | null
    login: string
    birthday: Date | null
    status: string | null
    urlAvatar: string
    bgColorAvatar: string
    workspaceAvatar: string
    roleId: number
    nickname: string | null
    emailConfirm: number | null
    email: string
    isOnline: boolean
    isMuted: boolean
    isPined: boolean
    lastMessage: IItemMessageOfTextChat
    unreadCount: number
    timestampEmailSend: Date
    unreadMessageIds: (number | string)[] | null
}

type IStore = {
    search: string
    userContacts: IItemChatUserOfListContacts[]
    setSearch(search: string): void
    setUserContacts(userContacts: IStore['userContacts']): void
}

export const useUserContactsStore = create<IStore>(set => ({
    search: '',
    userContacts: [],
    setSearch: search => set(state => ({ search })),
    setUserContacts: userContacts => set(state => ({ userContacts }))
}))

import Modal from 'components/Modal/Modal'
import ModalBody from 'components/Modal/ModalBody/ModalBody'
import ModalFooter from 'components/Modal/ModalFooter/ModalFooter'
import ModalHeader from 'components/Modal/ModalHeader/ModalHeader'
import { Button, IconButton, InputAdornment, TextField } from '@mui/material'
import { Form, Formik, FormikHelpers } from 'formik'
import { IUserProfile, useUserProfileStore } from 'store/user-profile'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { ISocketRequest, SocketAction } from 'api/ws/ws'
import { useWebSocketContext } from 'providers/WebSocketProvider'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import React from 'react'
import { checkLogin, checkPassword } from 'utils/helpers'

type IProps = {
    open: boolean
    onClose(): void
}

type IInitialValues = {
    passw: string
    log: string
}

export default function ModalEditLogin(props: IProps) {
    const { userProfile } = useUserProfileStore()
    const { sendJsonMessage, readyState, lastJsonMessage, lastMessage } = useWebSocketContext()
    const [showPassword, setShowPassword] = React.useState(false)

    const handleClose = () => {
        props.onClose()
    }

    const handleClickShowPassword = () => setShowPassword(show => !show)

    React.useEffect(() => {
        if (readyState !== ReadyState.OPEN) return
        switch (lastJsonMessage?.action) {
            case SocketAction.TOAST:
                if (lastJsonMessage?.payload.type === 'SUCCESS') {
                    handleClose()
                }
        }
    }, [lastJsonMessage, readyState])

    const onSubmit = (values: IInitialValues, formikHelpers: FormikHelpers<IInitialValues>) => {
        formikHelpers.setSubmitting(false)
        if (readyState !== ReadyState.OPEN) return
        sendJsonMessage<ISocketRequest>({
            action: SocketAction.UPDATE_USER_PROFILE_OF_WORKSPACE,
            payload: {
                roleId: Number(userProfile?.roleId),
                login: values.log,
                password: values.passw
            }
        })
        formikHelpers.resetForm()
    }

    return (
        <Modal width={454} open={props.open} onClose={handleClose}>
            <Formik
                enableReinitialize
                initialValues={{ passw: '', log: '' }}
                validate={values => {
                    const errors: Partial<IInitialValues> = {}
                    if (!values.passw) {
                        errors.passw = 'Обязательное поле'
                    }
                    /*                    if (values.passw !== userProfile?.password) {
                        errors.passw = 'Неверный пароль'
                    }*/

                    if (!values.log) errors.log = 'Обязательное поле'
                    if (checkLogin(values.log)) errors.log = checkLogin(values.log)

                    return errors
                }}
                onSubmit={onSubmit}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, handleReset, setFieldValue, isSubmitting, isValid, dirty }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <ModalHeader title="Изменить логин" onClose={props.onClose} />
                            <ModalBody>
                                <TextField
                                    label="Введите пароль"
                                    name="passw"
                                    type={showPassword ? 'text' : 'password'}
                                    variant="outlined"
                                    value={values.passw}
                                    error={touched.passw && !!errors.passw}
                                    helperText={touched.passw && errors.passw}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    size="medium"
                                    autoComplete={!showPassword ? 'off' : undefined}
                                />
                                <TextField
                                    label="Введите логин"
                                    name="log"
                                    type="text"
                                    variant="outlined"
                                    value={values.log}
                                    error={touched.log && !!errors.log}
                                    helperText={touched.log && errors.log}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    size="medium"
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button type="submit" variant="contained" color="primary" onClick={() => handleSubmit()} disabled={!(isValid && dirty)}>
                                    Сохранить
                                </Button>
                            </ModalFooter>
                        </Form>
                    )
                }}
            </Formik>
        </Modal>
    )
}

import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import MuiThemeProvider from 'providers/MuiThemeProvider'
import ReactQueryClientProvider from 'providers/ReactQueryClient'
import './index.css'
import WebSocketProvider from 'providers/WebSocketProvider'
import { SnackbarProvider, useSnackbar } from 'notistack'
import moment from 'moment'
// import ruLocalMoment from "moment/locale/"
import ruLocale from 'dayjs/locale/ru' // load on demand
import dayjs from 'dayjs'
import AgoraProvider from 'providers/AgoraProvider'
moment.locale('ru')
dayjs.locale(ruLocale)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
    //<React.StrictMode>
    <MuiThemeProvider>
        <SnackbarProvider>
            <ReactQueryClientProvider>
                <App />
            </ReactQueryClientProvider>
        </SnackbarProvider>
    </MuiThemeProvider>
    //</React.StrictMode>
)

reportWebVitals()
